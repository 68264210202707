// Clean hostname (www. & dev. & local.)
var hostname = window.location.hostname.replace(/^(www\.)/,"").replace(/^(dev\.)/,"").replace(/^(local\.)/,"");
var optionMapping = [
    {hostname: 'abtexel.margemonitor.nl', options: 'OptionsABT'},
    {hostname: 'boonstra.margemonitor.nl', options: 'OptionsBOO'},
    {hostname: 'bosdynamics.margemonitor.nl', options: 'OptionsBOS'},
    {hostname: 'brinks.margemonitor.nl', options: 'OptionsBRI'},
    {hostname: 'curfs.margemonitor.nl', options: 'OptionsCUR'},
    {hostname: 'demo.margemonitor.nl', options: 'OptionsDEMO'},
    {hostname: 'electroniclogistics.margemonitor.nl', options: 'OptionsELO'},
    {hostname: 'sandersfritom.margemonitor.nl', options: 'OptionsFRS'}, 
    {hostname: 'veenstrafritom.margemonitor.nl', options: 'OptionsFRV'}, 
    {hostname: 'hoekstra.margemonitor.nl', options: 'OptionsHOE'},
    {hostname: 'jankrediet.margemonitor.nl', options: 'OptionsJKS'},
    {hostname: 'lubbers.margemonitor.nl', options: 'OptionsLUB'},
    {hostname: 'qlik.lubbers.net', options: 'OptionsLUB'},
    {hostname: 'vdhlubqlsns01.vdhlub.local', options: 'OptionsLUB'},
    //{hostname: 'gesimons.margemonitor.nl', options: 'OptionsSIM'},
    {hostname: 'vredeveld.margemonitor.nl', options: 'OptionsVRE'},
    {hostname: 'evanwijk.margemonitor.nl', options: 'OptionsWIJ'},
    {hostname: 'wessels.margemonitor.nl', options: 'OptionsWTR'},
    {hostname: 'sense.gesimons.kasper.korton.net', options: 'OptionsSIM'},
    {hostname: 'qliksense.curfs.com', options: 'OptionsCUR'},
    {hostanme: 'qliksense.evanwijk.com', options: 'OptionsWIJ'},
    {hostname: 'localhost', options: 'OptionsLUB'},
]; 
var matches = optionMapping.filter(x => x.hostname === hostname);
var options = require('./options/OptionsDEMO');
if (matches[0]){
    options = require('./options/' + matches[0].options).default;
}
export default options; 