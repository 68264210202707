import {dimensions, measures} from './qlik/qlik';
export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            qlik: dimensions.driver.name,
            label: 'Naam',
            value: 'vD_Driver.Name'
          },
          {
            qlik: dimensions.driver.number, 
            label: 'Nummer',
            value: 'vD_Driver.Number'
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            qlik: dimensions.vehicle.number, 
            label: 'Naam',
            value: 'vD_Vehicle.Number'
          },
          {
            qlik: dimensions.vehicle.name,
            label: 'Nummer',
            value: 'vD_Vehicle.Name'
          }
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            qlik: dimensions.charter.name,
            label: 'Naam',
            value: 'vD_Trip.CreditorName'
          },
          {
            qlik: dimensions.charter.number, 
            label: 'Nummer',
            value: 'vD_Trip.CreditorNumber'
          }
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            qlik: dimensions.trailer.number, 
            label: 'Nummer',
            value: 'vD_Trip.TrailerNumber'
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: dimensions.trip.number, 
            value: 'vD_Trip.TripNumber',
            label: 'Technisch Trip Nummer'
          },
          {
            qlik: dimensions.trip.companyname,
            value: 'vD_Trip.Company.Name',
            label: 'Bedrijf'
          },
          {
            qlik: dimensions.trip.intercompany,
            value: 'vD_Trip.Company.Intercompany',
            label: 'Intercompany Bedrijf'
          },
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            qlik: dimensions.startlocation.id,
            value: 'vD_Trip.StartLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.startlocation.name, 
            value: 'vD_Order.StartLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.startlocation.searchcode,
            value: 'vD_Order.StartLocation.ZoekCode',
            label: 'ZoekCode'
          },
          
          {
            qlik: dimensions.startlocation.zipcode,
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats'
          },
          {
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land'
          },
          {
            value: 'vD_Order.StartLocation.District',
            label: 'District'
          },
          {
            value: 'vD_Order.StartLocation.Province',
            label: 'Provincie'
          },
          
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'vD_Trip.EndLocation.ID',
            label: 'ID'
          },
          {
            value: 'vD_Order.EndLocation.Name',
            label: 'Naam'
          },
          {
            value: 'vD_Order.EndLocation.ZoekCode',
            label: 'ZoekCode'
          },
          {
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats'
          },
          {
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land'
          },
          {
            value: 'vD_Order.EndLocation.District',
            label: 'District'
          },
          {
            value: 'vD_Order.EndLocation.Province',
            label: 'Provincie'
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            value: 'vD_Week',
            label: 'Week'
          },
          {
            value: 'vD_Date',
            label: 'Datum'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal,
            value: 'vE_TripOmzet',
            label: 'Totaal'
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_TripKosten',
            label: 'Totaal',
          },
          {
            value: 'vE_TripKostenCharter',
            label: 'Derden',
          },
          {
            value: 'vE_TripKostenTol',
            label: 'Tol',
          },
          {
            value: 'vE_TripKostenCleaning',
            label: 'Cleaning',
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.orders, 
            value: 'vE_TripAantalOrders',
            label: 'Order',
          },
          {
            qlik: measures.aantal.ritten,
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur, 
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK'
          },

          {
            qlik: measures.kpi.normmarginpercent,
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %'
          },
          {
            qlik: measures.kpi.normmarginomzet,
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: measures.own.omzetperuur,
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur'
          },
          {
            qlik: measures.own.bezetting,
            value: 'vE_KPI_TripBezetting',
            label: 'Bezetting'
          },
          {
            qlik: measures.own.normomzetperuur,
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur'
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            //qlik: measures.charter.
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge'
          },
          {
            value: 'vE_TripNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_TripKM',
            label: 'Totaal'
          },
          {
            value: 'vE_TripKMFull',
            label: 'Beladen'
          },
          {
            value: 'vE_TripKMEmpty',
            label: 'Onbeladen'
          },
          {
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            value: 'vE_KPI_TripOmzetPerKMFull',
            label: 'Omzet / beladen KM'
          },
          {
            value: 'vE_KPI_TripBelading',
            label: '% Beladen'
          },
          {
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
          {
            value: 'vE_TripNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM'
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
          },
          {
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
          },
          {
            value: 'vE_TripUrenCleaning',
            label: 'Spoelen (Σ)',
          },
          {
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
          },
          {
            value: 'vE_TripUrenAvgCleaning',
            label: 'Spoelen (avg)',
          },
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            value: 'vE_TripOmzetVorigJaar',
            label: 'Omzet',
          },
          {
            value: 'vE_TripAantalOrdersVorigJaar',
            label: 'Order',
          },
          {
            value: 'vE_TripAantalRittenVorigJaar',
            label: 'Ritten',
          },
          {
            value: 'vE_KPI_TripGoedkeurVorigJaar',
            label: 'OK'
          },

          {
            value: 'vE_KPI_TripNormMarginPercentVorigJaar',
            label: 'Norm Marge %'
          },
          {
            value: 'vE_KPI_TripNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge'
          },
          {
            value: 'vE_KPI_TripOmzetPerUurVorigJaar',
            label: 'Omzet / Uur'
          },
          {
            value: 'vE_KPI_TripTripOutsourcedVorigJaar',
            label: '% Uitbesteed'
          },
          {
            value: 'vE_KPI_TripCharterMargeVorigJaar',
            label: 'Derden Marge'
          },

        ],
      },
      {
        value: 'persize',
        label: 'Per Eenheid',
        children: [
          {
            value: 'vE_TripShipmentSize',
            label: 'Ton'
          },
          {
            value: 'vE_KPI_TripOmzetPerZendingGroottePervE_TripShipmentSize',
            label: 'Omzet Per Ton'
          },
          {
            value: 'vE_KPI_TripLaadMinutenPerZendingGrootte',
            label: 'Laad minuten per Ton'
          },
          {
            value: 'vE_KPI_TripLosMinutenPerZendingGrootte',
            label: 'Los minuten per ton'
          },
          {
            value: 'vE_KPI_TripMinutenPerZendingGrootte',
            label: 'Tijd per ton'
          },
        ]
      },
    ]
  }
];
