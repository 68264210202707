import {dimensions, measures} from './qlik/klant/klant';
import {dimensions as wij_dimensions, measures as wij_measures} from './qlik/klant/wij';
import {measures as co2measures} from './qlik/co2';


export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Klant',
          value: 'klant',
          children: [
            {
              qlik: dimensions.debtor.name,
              label: 'Naam',
              new_value: "dimensions.debtor.name",
              value: 'vD_Order.Debtor.Name',
            },
            {
              qlik: dimensions.debtor.number,
              label: 'Nummer',
              new_value: "dimensions.debtor.number",
              value: 'vD_Order.Debtor.Number'

            },
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              qlik: dimensions.order.ordernumberdisplay,
              new_value: 'dimensions.order.ordernumberdisplay',
              label: 'Order Nummer',
              value: 'vD_Order.OrderNumberDisplay',

            },
            {
              qlik: dimensions.order.dossier,
              new_value: 'dimensions.order.dossier',
              label: 'Dossier',
              value: 'vD_Order.Dossier',
            },
          ]
        },
        {
          label: 'Overig',  
          value: 'overig',
          children: [
            {
              qlik: wij_dimensions.other.group,
              new_value: 'wij_dimensions.other.group',
              label: 'Afdeling',
              value: 'vD_Order.Group',

            },
            {
              qlik: dimensions.order.kinddescription,
              new_value: 'dimensions.order.kinddescription',
              label: 'Ordersoort',
              value: 'vD_Order.KindDescription'
            },
            {
              qlik: dimensions.order.reference,
              new_value: 'dimensions.order.reference',
              label: 'Referentie',
              value: 'vD_Order.Reference'
            },
            {
              qlik: dimensions.order.status,
              new_value: 'dimensions.order.status',
              label: 'Status',
              value: 'vD_Order.Status'
            },
            {
              qlik: dimensions.order.plandivision,
              new_value: 'dimensions.order.plandivision',
              label: 'SoortRit',
              value: 'vD_Order.PlanDivision'
            }
          ]
        },
        {
          label: 'Laadlocatie',
          value: 'laadlocatie',
          children: [
            {
              qlik: dimensions.loadinglocation.id, 
              new_value: 'dimensions.loadinglocation.id',
              label: 'ID',
              value: 'vD_Order.LoadingLocation.ID'
            },
            {
              qlik: dimensions.loadinglocation.name,
              new_value: 'dimensions.loadinglocation.name',
              label: 'Naam',
              value: 'vD_Order.LoadingLocation.Name'
            },
            {
              qlik: dimensions.loadinglocation.adress,
              new_value: 'dimensions.loadinglocation.adress',
              label: 'Adres',
              value: 'vD_Order.LoadingLocation.Adress'
            },
            {
              qlik: dimensions.loadinglocation.zipcode,
              new_value: 'dimensions.loadinglocation.zipcode',
              label: 'Postcode',
              value: 'vD_Order.LoadingLocation.ZIPcode'
            },
            {
              qlik: dimensions.loadinglocation.city,
              new_value: 'dimensions.loadinglocation.city',
              label: 'Plaats',
              value: 'vD_Order.LoadingLocation.City'
            },
            {
              qlik: dimensions.loadinglocation.district,
              new_value: 'dimensions.loadinglocation.district',
              label: 'Gemeente', 
              value: 'vD_Order.LoadingLocation.District'
            },
            {
              qlik: dimensions.loadinglocation.province,
              new_value: 'dimensions.loadinglocation.province',
              label: 'Provincie',
              value: 'vD_Order.LoadingLocation.Province'
            },
            {
              qlik: dimensions.loadinglocation.country,
              new_value: 'vD_Order.LoadingLocation.Country',
              label: 'Land',
              value: 'vD_Order.LoadingLocation.Country'
            },
            {
              qlik: dimensions.loadinglocation.land,
              new_value: 'dimensions.loadinglocation.land',
              label: 'Land (ISO)',
              value: 'vD_Order.LoadingLocation.Land'
            },
          ]
        },
        {
          label: 'Loslocatie',
          value: 'loslocatie',
          children: [
            {
              qlik: dimensions.unloadinglocation.id,
              new_value: 'dimensions.unloadinglocation.id',
              label: 'ID',
              value: 'vD_Order.UnloadingLocation.ID'
            },
            {
              qlik: dimensions.unloadinglocation.name,
              new_value: 'dimensions.unloadinglocation.name',
              label: 'Naam',
              value: 'vD_Order.UnloadingLocation.Name'
            },
            {
              qlik: dimensions.unloadinglocation.adress,
              new_value: 'dimensions.unloadinglocation.adress',
              label: 'Adres',
              value: 'vD_Order.UnloadingLocation.Adress'
            },
            {
              qlik: dimensions.unloadinglocation.zipcode,
              new_value: 'dimensions.unloadinglocation.zipcode',
              label: 'Postcode',
              value: 'vD_Order.UnloadingLocation.ZIPcode'
            },
            {
              qlik: dimensions.unloadinglocation.city,
              new_value: 'dimensions.unloadinglocation.city',
              label: 'Plaats',
              value: 'vD_Order.UnloadingLocation.City'
            },
            {
              qlik: dimensions.unloadinglocation.district,
              new_value: 'vD_Order.UnloadingLocation.District',
              label: 'Gemeente',
              value: 'vD_Order.UnloadingLocation.District',
            },
            {
              qlik: dimensions.unloadinglocation.province,
              new_value: 'dimensions.unloadinglocation.province',
              label: 'Provincie',
              value: 'vD_Order.UnloadingLocation.Province'
            },
            {
              qlik: dimensions.unloadinglocation.country,
              new_value: 'dimensions.unloadinglocation.country',
              label: 'Land',
              value: 'vD_Order.UnloadingLocation.Country'
            },
            {
              qlik: dimensions.unloadinglocation.land,
              new_value: 'dimensions.unloadinglocation.land',
              label: 'Land (ISO)',
              value: 'vD_Order.UnloadingLocation.Land'
            },
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              qlik: dimensions.period.year,
              new_value: 'dimensions.period.year',
              label: 'Jaar',
              value: 'vD_Year'
            },
            {
              qlik: dimensions.period.quarter,
              new_value: 'dimensions.period.quarter',
              label: 'Kwartaal',
              value: 'vD_Quarter'
            },
            {
              qlik: dimensions.period.month,
              new_value: 'dimensions.period.month',
              label: 'Maand',
              value: 'vD_Month'
            },
            {
              qlik: dimensions.period.week,
              new_value: 'dimensions.period.week',
              label: 'Week',
              value:'vD_Week'
            },
            {
              qlik: dimensions.period.date,
              new_value: 'dimensions.period.date',
              label: 'Datum',
              value: 'vD_Date'
            },
            {
              qlik: dimensions.period.weekday,
              new_value: 'dimensions.period.weekday',
              label: 'Weekday',
              value: 'vD_Weekday'
            },
          ]
        },
        {
          label: 'Zendinggroote',
          value: 'zendinggroote',
          children: [
            {
              qlik: wij_dimensions.zendinggrootte.klasse,
              value: 'vD_Order.Klasse.Size',
              label: 'Klasse - Grootte'
            },
            {
              qlik: wij_dimensions.zendinggrootte.klasse_lm,
              value: 'vD_Order.Klasse.LM',
              label: 'Klasse - LM'
            }
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              qlik: measures.omzet.totaal,
              value: 'vE_OrderOmzet',
              label: 'Totaal'
            },
            {
              qlik: measures.omzet.freight,
              value: 'vE_OrderOmzet.Vracht',
              label: 'Vracht'
            },
            {
              qlik: measures.omzet.fuel,
              value: 'vE_OrderOmzet.Fuel',
              label: 'Brandstof'
            },
            {
              qlik: measures.omzet.other,
              value: 'vE_OrderOmzet.Other',
              label: 'Overig'
            }
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              qlik: measures.kosten.totaal,
              value: 'vE_OrderKosten',
              label: 'Totaal',
            },
            {
              qlik: measures.kosten.charter,
              value: 'vE_OrderKostenCharter',
              label: 'Derden',
            },
            {
              qlik: measures.kosten.toll,
              value: 'vE_OrderKostenTol',
              label: 'Tol',
            },
            {
              qlik: measures.kosten.codriver,
              value: 'vE_OrderKostenCoDriver',
              label: 'Bijrijder',
            },
            {
              qlik: measures.kosten.warehouse,
              value: 'vE_OrderKostenWarehouse',
              label: 'Warehouse',
            },
            {
              qlik: measures.kosten.other,
              value: 'vE_OrderKostenOther',
              label: 'Other',
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              qlik: measures.aantal.orders,
              value: 'vE_OrderAantalOrders',
              label: 'Order',
            },
            {
              qlik: measures.aantal.ritten,
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              qlik: measures.kpi.goedkeur,
              value: 'vE_KPI_OrderGoedkeur',
              label: 'OK'
            },
  
            {
              qlik: measures.kpi.normmarginpercent,
              value: 'vE_KPI_OrderNormMarginPercent',
              label: 'Norm Marge %'
            },
            {
              qlik: measures.kpi.normmarginomzet,
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              label: 'Norm Marge'
            },
  
  
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              qlik: measures.own.omzetperuur,
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur'
            },
            {
              qlik: measures.own.tripomzetperuur,
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit'
            },
            {
              qlik: measures.own.normomzetperuur,
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur'
            },
            {
              qlik: measures.own.bezetting,
              value: 'vE_KPI_OrderBezetting',
              label: 'Bezetting'
            }
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              qlik: measures.outsourced.percentage,
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed'
            },
            {
              qlik: measures.outsourced.marge,
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge'
            },
            {
              qlik: measures.outsourced.norm,
              value: 'vE_OrderNormCharter',
              label: 'Norm'
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              qlik: measures.potentie.totaal,
              value: 'vE_OrderPotency',
              label: 'Totaal'
            },
            {
              qlik: measures.potentie.commercieel,
              value: 'vE_OrderPotencyCommercial',
              label: 'Commercieel'
            },
            {
              qlik: measures.potentie.operationeel,
              value: 'vE_OrderPotencyOperational',
              label: 'Operationeel'
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              qlik: measures.km.km,
              value: 'vE_OrderKM',
              label: 'Totaal'
            },
            {
              qlik: measures.km.omzetperkm,
              value: 'vE_KPI_OrderOmzetPerKM',
              label: 'Omzet / KM'
            },
            {
              qlik: measures.km.normomzetperkm,
              value: 'vE_OrderNormTurnoverKM',
              label: 'Norm Omzet / KM'
            }
          ]
        },
        {
          value: 'CO2',
          label: 'CO2',
          children: [
            {
              qlik: co2measures.co2.rekenkilometers,
              value: 'vE_OrderCo2KM',
              label: 'Rekenkilometers',
              help: 'De kilometers voor de CO2, indien niet aanwezig worden zijn deze geinterpoleerd op basis van de afstand en zendinggrootte'
            },
            {
              qlik: co2measures.co2.verbruikperkm,
              value: 'vE_OrderCo2Verbruik',
              label: 'Vebruik per km',
              help: 'Het brandstofverbruik per kilometer, op dit moment een vaste norm vastgesteld op 0.3 liter per km'
            },
            {
              qlik: co2measures.co2.litersbrandstof,
              value: 'vE_OrderCo2L',
              label: 'Liters Brandstof',
              help: 'Het aantal liters brandstof op basis van het aantal rekenkilometers in combinatie met het brandstofverbruik'
            },
            {
              qlik: co2measures.co2.uitstootfactorwtw,
              value: 'vE_TripCo2FactorWTW',
              label: 'Uitstoot factor WTW',
              help: 'De factor voor de uitstoot van een liter brandstof WellToWheel (WTW)'
            },
            {
              qlik: co2measures.co2.uitstootfactorttw,
              value: 'vE_TripCo2FactorTTW',
              label: 'Uitstoot factor TTW',
              help: 'De factor voor de uitstoot van een liter brandstof TankToWheel (TTW)'
            },
            {
              qlik: co2measures.co2.co2uitstootwtw,
              value: 'vE_TripCo2TotaalWTW',
              label: 'CO2 uitstoot (WTW)',
            },
            {
              qlik: co2measures.co2.co2uitstootttw,
              value: 'vE_TripCo2TotaalTTW',
              label: 'CO2 uitstoot (TTW)',
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              qlik: measures.uren.totaal,
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
            },
            {
              qlik: measures.uren.loading,
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
            },
            {
              qlik: measures.uren.unloading,
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
            },
            {
              qlik: measures.uren.driving,
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
            },
            {
              qlik: measures.uren.other,
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
            },
            {
              qlik: measures.uren.avg_totaal,
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
            },
            {
              qlik: measures.uren.avg_loading,
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
            },
            {
              qlik: measures.uren.avg_unloading,
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
            },
            {
              qlik: measures.uren.avg_driving,
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
            },
            {
              qlik: measures.uren.avg_other,
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
            }
          ]
        },
        {
          value: 'vergelijkperiode',
          label: 'Voorgaand jaar',
          children: [
            {
              qlik: measures.previousyear.omzet,
              value: 'vE_OrderOmzetVorigJaar',
              label: 'Omzet',
            },
            {
              qlik: measures.previousyear.orders,
              value: 'vE_OrderAantalOrdersVorigJaar',
              label: 'Order',
            },
            {
              qlik: measures.previousyear.ritten,
              value: 'vE_OrderAantalRittenVorigJaar',
              label: 'Ritten',
            },
            {
              qlik: measures.previousyear.goedkeur,
              value: 'vE_KPI_OrderGoedkeurVorigJaar',
              label: 'OK'
            },
  
            {
              qlik: measures.previousyear.normmarge,
              value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
              label: 'Norm Marge %'
            },
            {
              qlik: measures.previousyear.normmargeomzet,
              value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
              label: 'Norm Marge'
            },
            {
              qlik: measures.previousyear.omzetperuur,
              value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
              label: 'Omzet / Uur'
            },
            {
              qlik: measures.previousyear.outsourced,
              value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
              label: '% Uitbesteed'
            },
            {
              qlik: measures.previousyear.chargermarge,
              value: 'vE_KPI_OrderCharterMargeVorigJaar',
              label: 'Derden Marge'
            },
  
          ],
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            // {
            //   label: 'KG',
            //   value: 'persize.KG',
            //   children: [
            //     {
            //       qlik: measures.persize.kg,
            //       value: 'measures.persize.kg',
            //       label: 'KG'
            //     },
            //     {
            //       qlik: measures.persize.avg_kg,
            //       value: 'measures.persize.avg_kg',
            //       label: 'KG (avg)'
            //     },

            //   ]
            // },
            {
              label: 'LM',
              value: 'persize.LM',
              children: [
                {
                  qlik: measures.persize.shipmentsize,
                  value: 'vE_OrderShipmentSize',
                  label: 'LM'
                },
                {
                  qlik: measures.persize.shipmentsizeavg,
                  value: 'vE_OrderShipmentAvgSize',
                  label: 'LM (avg)'
                },
                {
                  qlik: measures.persize.omzet_shipmentsize,
                  value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
                  label: 'Omzet Per LM'
                },
                {
                  qlik: measures.persize.loading_shipmentsize,
                  value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
                  label: 'Laad minuten per LM'
                },
                {
                  qlik: measures.persize.unloading_shipmentsize,
                  value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
                  label: 'Los minuten per LM'
                },
                {
                  qlik: measures.persize.time_shipmentsize,
                  value: 'vE_KPI_OrderMinutenPerZendingGrootte',
                  label: 'Tijd per LM'
                },
              ]
            }
          ]
        },
      ]
    }
  ];
  