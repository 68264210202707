import React from 'react';

import orderList from '../../margemonitor/components/orders/dimensions-BOO';
import ritList from '../../margemonitor/components/ritten/dimensions-BOO';
import orderTripList from '../../margemonitor/components/trajecten/dimensions-BOO';

const KM_aansluiting = React.lazy(() => import('./../../views/Maatwerk/WIJ/KM-aansluiting'));
const Uren_aansluiting = React.lazy(() => import('./../../views/Maatwerk/WIJ/ControleUren'));
const NarekenenKPI = React.lazy(() => import('./../../views/Maatwerk/FRV/NarekenenKPI'));
const Weekrapportage = React.lazy(() => import('./../../views/Maatwerk/BOO/Weekrapportage'));



const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'transportcostconsult.eu.qlikcloud.com',
    secure: true,
    port: '443',
    prefix: '',
    webIntegrationId: 'UC7aldzKKXMMGqblEErpfq2xZ-LgCxNv',  
    //appId: "1111a56b-1d0d-4754-a205-bde5ccf1b2f2",
    appId: localStorage.getItem('appId') ? localStorage.getItem('appId') : "367b8e10-e66f-4a2a-b272-c1226dc36241"
  },
  connections: {
    spaceId: '60f9b2bec696df79bae4bce1',
    qlikCloud: true, 
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Year)'],
            placeholder: 'Jaar',
            expanded: true, 
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Quarter)'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Month)'],
            placeholder: 'Maand',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Period)'],
            placeholder: 'Periode',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_YearWeek)'],
            placeholder: 'JaarWeek',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Week)'],
            placeholder: 'Week',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Weekday)]'],
            placeholder: 'Dag',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          }
        },   
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Date)'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Name)]'],
            placeholder: 'Naam',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.SearchCode)]'],
            placeholder: 'Zoekcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.District)]'],
            placeholder: 'Gemeente',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Province)]'],
            placeholder: 'Provincie',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Land)]'],
            placeholder: 'Land (ISO)',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Name)]'],
            placeholder: 'Naam',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.SearchCode)]'],
            placeholder: 'Zoekcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.District)]'],
            placeholder: 'Gemeente',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Province)]'],
            placeholder: 'Provincie',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Land)]'],
            placeholder: 'Land (ISO)',
          },
        },
      ],
    },order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Zending',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Dossier)]'],
            placeholder: 'Dossier',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Kenmerken'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.PlanGroup)]'],
            placeholder: 'Plangroep',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Reference)]'],
            placeholder: 'Referentie',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Status)]'],
            placeholder: 'Status',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Opdrachtgever'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Order.Customer.Name)'],
            placeholder: 'Naam',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Customer.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Debiteur'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'Number',
            showStateInDropdown: true,
          },
        }
      ],
    },
    trip: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Kenmerken" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.ResourceCombination)]'],
            placeholder: 'Resource Combination',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.PlanGroup)]'],
            placeholder: 'Plangroep',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Vehicle.Type)]'],
            placeholder: 'Type',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.SearchCode)]'],
            placeholder: 'Zoekcode',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Soort',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
        {
          title: true,
          name: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          title: true,
          name: 'Maatwerk',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          name: 'KM aansluiting',
          url: '/boo/km-aansluiting',
          icon: 'fa fa-check',
        },
        {
          name: 'Uren aansluiting',
          url: '/boo/uren-aansluiting',
          icon: 'fa fa-clock',
        },
        {
          name: 'Narekenen KPI',
          url: '/boo/narekenen-kpi',
          icon: 'fa fa-calculator'
        },
        {
          name: 'Weekrapportage',
          url: '/boo/weekrapportage',
          icon: 'fa fa-calendar-week'
        }
      ],
  }, 
  routes: [
    { path: '/boo/km-aansluiting', name: 'BOO / KM aansluiting', component: KM_aansluiting },
    { path: '/boo/uren-aansluiting', name: 'BOO / Uren aansluiting', component: Uren_aansluiting },
    { path: '/boo/narekenen-kpi', name: 'BOO / Narekenen KPI', component: NarekenenKPI },
    { path: '/boo/weekrapportage', name: 'BOO / Weekrapportage', component: Weekrapportage },
    //{ path: '/wij/controle/uren', name: 'WIJ / Controle / Uren', component: UrenControle },
  ],
  fieldSettings:{
    period: 'vD_Month'
  },
  selectionBar: 'Nebula',
  rollingPeriod: false,
  //language: 'en',
  defaultSelections: [
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
  ]

};

export default options;
