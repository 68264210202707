const measures = {
  co2: {
      rekenkilometers: {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "CO2 > Rekenkilometers",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "num($(vExpr_SumGroupedBy(OrderTrip, CO2.Distance, $(vS_OrderTrip_DateType))),'#.##0;-#.##0')",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
      verbruikperkm:   {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "CO2 > Verbruik per KM",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "num($(vExpr_AvgGroupedBy(OrderTrip, [CO2.Verbruik], $(vS_OrderTrip_DateType))),'#.##0,00;-#.##0,00')",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
      litersbrandstof:   {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "CO2 > Liters Brandstof",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "num($(vExpr_SumGroupedBy(OrderTrip, CO2.Brandstof, $(vS_OrderTrip_DateType))),'#.##0;-#.##0')",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
      uitstootfactorwtw: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "CO2 > Factor WTW",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "num($(vExpr_AvgGroupedBy(OrderTrip, [CO2.FactorWTW], $(vS_OrderTrip_DateType))),'#.##0,00;-#.##0,00')",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      uitstootfactorttw:  {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "CO2 > Factor TTW",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "num($(vExpr_AvgGroupedBy(OrderTrip, [CO2.FactorTTW], $(vS_OrderTrip_DateType))),'#.##0,00;-#.##0,00')",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
      co2uitstootwtw:  {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "CO2 > Uitstoot WTW",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "num($(vExpr_SumGroupedBy(OrderTrip, CO2.UitstootWTW, $(vS_OrderTrip_DateType))),'#.##0;-#.##0')",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
      co2uitstootttw:  {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "CO2 > Uitstoot TTW",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "num($(vExpr_SumGroupedBy(OrderTrip, CO2.UitstootTTW, $(vS_OrderTrip_DateType))),'#.##0;-#.##0')",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
  }
};

export {measures};