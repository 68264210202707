import {dimensions, measures} from './qlik/qlik';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            qlik: dimensions.driver.name,
            label: 'Naam',
            value: 'vD_Driver.Name'
          },
          {
            qlik: dimensions.driver.number,
            label: 'Nummer',
            value: 'vD_Driver.Number'
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            qlik: dimensions.vehicle.number,
            label: 'Naam',
            value: 'vD_Vehicle.Number'
          },
          {
            qlik: dimensions.vehicle.name,
            label: 'Nummer',
            value: 'vD_Vehicle.Name'
          }
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            qlik: dimensions.charter.name,
            label: 'Naam',
            value: 'vD_Trip.Creditor.Name'
          },
          {
            qlik: dimensions.charter.number,
            label: 'Nummer',
            value: 'vD_Trip.Creditor.Number'
          }
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            qlik: dimensions.trailer.number,
            label: 'Nummer',
            value: 'vD_Trip.TrailerNumber'
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: dimensions.trip.number,
            value: 'vD_Trip.TripNumber',
            label: 'Technisch Trip Nummer'
          }
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            qlik: dimensions.startlocation.id,
            value: 'vD_Trip.StartLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.startlocation.name,
            value: 'vD_Trip.StartLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.startlocation.zipcode,
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.startlocation.city,
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.startlocation.country,
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land'
          },
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            qlik: dimensions.endlocation.id,
            value: 'vD_Trip.EndLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.endlocation.name,
            value: 'vD_Trip.EndLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.endlocation.zipcode,
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.endlocation.city,
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.endlocation.country,
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land'
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: dimensions.period.year,
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            qlik: dimensions.period.quarter,
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: dimensions.period.month,
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: dimensions.period.week,
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: dimensions.period.date,
            value: 'vD_Date',
            label: 'Datum'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal,
            value: 'vE_TripOmzet',
            label: 'Totaal'
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal,
            value: 'vE_TripKosten',
            label: 'Totaal',
          },
          {
            qlik: measures.kosten.charter,
            value: 'vE_TripKostenCharter',
            label: 'Derden',
          },
          {
            qlik: measures.kosten.rail,
            value: 'vE_TripKostenRailFerry',
            label: 'Trein',
          },
          {
            qlik: measures.kosten.toll,
            value: 'vE_TripKostenTol',
            label: 'Tol',
          },
          {
            qlik: measures.kosten.cleaning,
            value: 'vE_TripKostenCleaning',
            label: 'Cleaning',
          },
          {
            qlik: measures.kosten.codriver,
            value: 'vE_TripKostenCoDriver',
            label: 'Bijrijder',
          },
          {
            value: 'vE_TripKostenCollect',
            label: 'Voorhaal',
            qlik: measures.kosten.collect,
          },
          {
            value: 'vE_TripKostenWarehouse',
            label: 'Warehouse',
            qlik: measures.kosten.warehouse,
          },
          {
            value: 'vE_TripKostenOther',
            label: 'Other',
            qlik: measures.kosten.other,
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_TripAantalOrders',
            label: 'Order',
            qlik: measures.aantal.orders
          },
          {
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },

          {
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur
          },
          {
            value: 'vE_KPI_TripBezetting',
            label: 'Bezetting',
            qlik: measures.own.bezetting
          },
          {
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage
          },
          {
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge
          },
          {
            value: 'vE_TripNormCharter',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_TripKM',
            label: 'Totaal',
            qlik: measures.km.km
          },
          {
            value: 'vE_TripKMFull',
            label: 'Beladen',
            qlik: measures.km.kmfull
          },
          {
            value: 'vE_TripKMEmpty',
            label: 'Onbeladen',
            qlik: measures.km.kmempty
          },
          {
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm 
          },
          {
            value: 'vE_KPI_TripOmzetPerKMFull',
            label: 'Omzet / beladen KM',
            qlik: measures.km.omzetperkmfull
          },
          {
            value: 'vE_KPI_TripBelading',
            label: '% Beladen',
            qlik: measures.km.belading
          },
          {
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm
          },
          {
            value: 'vE_TripNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            qlik: measures.km.normomzetperkmfull
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal
          },
          {
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading
          },
          {
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading
          },
          {
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving
          },
          {
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other
          },
          {
            value: 'vE_TripUrenCleaning',
            label: 'Spoelen (Σ)',
            qlik: measures.uren.cleaning
          },
          {
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading,
          },
          {
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading,
          },
          {
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving,
          },
          {
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
            qlik: measures.uren.avg_other,

          },
          {
            value: 'vE_TripUrenAvgCleaning',
            label: 'Spoelen (avg)',
            qlik: measures.uren.avg_cleaning,
          },
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            value: 'vE_TripOmzetVorigJaar',
            label: 'Omzet',
            qlik: measures.previousyear.omzet,
          },
          {
            value: 'vE_TripAantalOrdersVorigJaar',
            label: 'Order',
            qlik: measures.previousyear.orders,
          },
          {
            value: 'vE_TripAantalRittenVorigJaar',
            label: 'Ritten',
            qlik: measures.previousyear.ritten,
          },
          {
            value: 'vE_KPI_TripGoedkeurVorigJaar',
            label: 'OK',
            qlik: measures.previousyear.goedkeur
          },

          {
            value: 'vE_KPI_TripNormMarginPercentVorigJaar',
            label: 'Norm Marge %',
            qlik: measures.previousyear.normmarge
          },
          {
            value: 'vE_KPI_TripNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge',
            qlik: measures.previousyear.normmargeomzet
          },
          {
            value: 'vE_KPI_TripOmzetPerUurVorigJaar',
            label: 'Omzet / Uur',
            qlik: measures.previousyear.omzetperuur
          },
          {
            value: 'vE_KPI_TripOmzetPerUurCombinatieVolgendeOrderVorigJaar',
            label: 'Omzet / Uur - rondrit',
            qlik: measures.previousyear.volgendeorder
          },
          {
            value: 'vE_KPI_TripTripOutsourcedVorigJaar',
            label: '% Uitbesteed',
            qlik: measures.previousyear.uitbesteed
          },
          {
            value: 'vE_KPI_TripCharterMargeVorigJaar',
            label: 'Derden Marge',
            qlik: measures.previousyear.chargermarge
          },

        ],
      },
    ]
  }
];
