import qlik from './qlik';
import {dimensions, measures} from './qlik/klant/klant';
import {dimensions as lub_dimensions, measures as lub_measures} from './qlik/klant/lub';
export default [
    {
      value: 'dimensions',
      label: 'Dimensions',
      class: 'bg-primary',
      children: [
        {
          label: 'Company', 
          value: 'company',
          children: [
            {
              value: 'dimensions.order.companyname',
              label: 'Company Name',
              qlik: lub_dimensions.other.companyname
            },
            {
              value: 'dimensions.order.companynumber',
              label: 'Company Number',
              qlik: lub_dimensions.other.companynumber
            },
          ]
        },
        {
          label: 'Debtor',
          value: 'debiteur',
          children: [
            {
              value: "dimensions.debtor.name",
              label: 'Name',
              qlik: dimensions.debtor.name,
            },
            {
              value: "dimensions.debtor.number",
              label: 'Number',
              qlik: dimensions.debtor.number
            },
            {
              value: "dimensions.debtor.country",
              label: 'Country',
              qlik: lub_dimensions.debtor.country
            }
          ]
        },
        {
          label: 'CIP',
          value: 'cip',
          children: [
            {
              value: "dimensions.cip.verticalname",
              label: 'Vertical Name',
              qlik: lub_dimensions.cip.verticalname,
            },
            {
              value: "dimensions.cip.region",
              label: 'Region',
              qlik: lub_dimensions.cip.region,
            },
            {
              value: "dimensions.cip.groupaccountname",
              label: 'Group Account Name',
              qlik: lub_dimensions.cip.groupaccountname
            },
            {
              value: "dimensions.cip.accountmanager",
              label: 'Account Manager',
              qlik: lub_dimensions.cip.accountmanager
            },
            {
              value: "dimensions.cip.accountmanagername",
              label: 'Account Manager Name',
              qlik: lub_dimensions.cip.accountmanagername
            },
            {
              value: "dimensions.cip.accountmanagercountry",
              label: 'Account Manager Country',
              qlik: lub_dimensions.cip.accountmanagercountry
            },
            {
              value: "dimensions.cip.groupverticalname",
              label: 'Group Vertical Name',
              qlik: lub_dimensions.cip.groupverticalname
            },
            {
              value: "dimensions.cip.groupaccountmanager",
              label: 'Group Account Manager',
              qlik: lub_dimensions.cip.groupaccountmanager
            },
            {
              value: "dimensions.cip.keyaccountgroupname",
              label: 'Key Account Group Name',
              qlik: lub_dimensions.cip.keyaccountgroupname
            },
            {
              value: "dimensions.cip.keyaccount",
              label: 'Key Account',
              qlik: lub_dimensions.cip.keyaccount
            },
            {
              value: "dimensions.cip.currency",
              label: 'Currency',
              qlik: lub_dimensions.cip.currency
            }
          ]
        },
        {
          label: 'Job',
          value: 'order',
          children: [
            {
              value: 'dimensions.order.ordernumberdisplay',
              label: 'Job',
              qlik: dimensions.order.ordernumberdisplay
            },
            {
              value: 'dimensions.order.kind',
              label: 'Kind',
              qlik: dimensions.order.kind
            },
            {
              value: 'dimensions.order.service',
              label: 'Service',
              qlik: lub_dimensions.other.service
            },
          ]
        },
        {
          label: 'Loading Location',
          value: 'laadlocatie',
          children: [
            {
              value: 'dimensions.loadinglocation.name',
              label: 'Name',
              qlik: dimensions.loadinglocation.name
            },
            {
              value: 'dimensions.loadinglocation.number',
              label: 'ID',
              qlik: dimensions.loadinglocation.id
            },
            {
              value: 'dimensions.loadinglocation.zipcode',
              label: 'ZIP code',
              qlik: dimensions.loadinglocation.zipcode
            },
            {
              value: 'dimensions.loadinglocation.city',
              label: 'City',
              qlik: dimensions.loadinglocation.city
            },
            {
              value: 'dimensions.loadinglocation.country',
              label: 'Country',
              qlik: dimensions.loadinglocation.country
            }
          ]
        },
        {
          label: 'Unloading Location',
          value: 'loslocatie',
          children: [
            {
              value: 'dimensions.unloadinglocation.name',
              label: 'Naam',
              qlik: dimensions.unloadinglocation.name
            },
            {
              value: 'dimensions.unloadinglocation.number',
              label: 'ID',
              qlik: dimensions.unloadinglocation.id
            },
            {
              value: 'dimensions.unloadinglocation.zipcode',
              label: 'ZIP code',
              qlik: dimensions.unloadinglocation.zipcode
            },
            {
              value: 'dimensions.unloadinglocation.city',
              label: 'City',
              qlik: dimensions.unloadinglocation.city
            },
            {
              value: 'dimensions.unloadinglocation.country',
              label: 'Country',
              qlik: dimensions.unloadinglocation.country
            },
          ]
        },
        {
          label: 'Period',
          value: 'periode',
          children: [
            {
              value: 'dimensions.period.year',
              label: 'Year',
              qlik: dimensions.period.year
            },
            {
              value: 'dimensions.period.quarter',
              label: 'Quarter',
              qlik: dimensions.period.quarter
            },
            {
              value: 'dimensions.period.month',
              label: 'Month',
              qlik: dimensions.period.month
            },
            {
              value: 'dimensions.period.period',
              label: 'Period',
              qlik: dimensions.period.period
            },
            {
              value: 'dimensions.period.week',
              label: 'Week',
              qlik: dimensions.period.week
            },
            {
              value: 'dimensions.period.date',
              label: 'Date',
              qlik: dimensions.period.date
            }
          ]
        }
      ]
    },
    {
      label: 'Measures',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Turnover',
          children: [
            {
              value: 'measures.omzet.totaal',
              label: 'Total',
              qlik: measures.omzet.totaal
            },
            {
              value: 'measures.omzet.totaalperorder',
              label: "Turnover / Job",
              qlik: lub_measures.omzet.totaalperjob
            }
          ]
        },
        {
          value: 'kosten',
          label: 'Costs',
          children: [
            {
              value: 'measures.kosten.totaal',
              label: 'Total',
              qlik: measures.kosten.totaal,
              help: 'Total direct costs van de directe kosten.'
            },
            {
              value: 'measures.kosten.charter',
              label: 'Charter', 
              qlik: measures.kosten.charter,
              help: 'Kosten van kilometer, uren en vaste charters'
            },
            {
              value: 'measures.kosten.toll',
              label: 'Toll',
              qlik: measures.kosten.toll,
              help: 'Tolkosten worden enkel vanuit Transpas meegenomen wanneer ze geregistreerd worden op een eigen auto'
            },
            {
              value: 'measures.kosten.warehouse',
              label: 'Warehouse',
              qlik: measures.kosten.warehouse,
              help: 'Voorcalculatorische kosten vanuit Transpas voor de reservering van de directe Warehouse kosten per zending'
            },
            {
              value: 'measures.kosten.rail',
              label: 'Rail / Ferry',
              qlik: measures.kosten.rail,
              help: 'Trein kosten vanuit Transpas voor de reservering van de rail kosten per zending'
            },
            {
               value: 'measures.kosten.other',
               label: 'Other',
               qlik: measures.kosten.other,
               help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Count',
          children: [            
            {
              value: 'vE_OrderAantalOrders',
              label: 'Jobs',
              qlik: measures.aantal.orders,
              help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
            },
            {
              value: 'vE_OrderAantalRitten',
              label: 'Trips',
              qlik: measures.aantal.ritten,
              help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              value: 'measures.kpi.goedkeur',
              label: 'OK',
              qlik: measures.kpi.goedkeur,
              help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kerngetallen.'
            },
  
            {
              value: 'measures.kpi.normmarginpercent',
              label: 'Norm Margin %',
              qlik: measures.kpi.normmarginpercent,
              help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
            },
            {
              value: 'measures.kpi.normmarginomzet',
              label: 'Norm Margin',
              qlik: measures.kpi.normmarginomzet,
              help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
            },
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Own Vehicle',
          children: [
            {
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'TO / Hour', 
              qlik: measures.own.omzetperuur,
              help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            },
            {
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'TO / Hour - Trip',
              qlik: measures.own.tripomzetperuur,
              help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
            },
            {
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm TO / Uur',
              qlik: measures.own.normomzetperuur,
              help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '

            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Charter',
          children: [
            {
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Charter',
              qlik: measures.outsourced.percentage,
              help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
            },
            {
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Margin',
              qlik: measures.outsourced.marge,
              help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. '
            },
            {
              value: 'vE_OrderNormCharter',
              label: 'Norm',
              qlik: measures.outsourced.norm,
              help: 'De geconfigureerde norm op orderniveau  voor het uitbesteede werk De Derden Marge wordt getoetst aan deze norm. '
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potency',
          children: [
            {
              value: 'measures.potentie.totaal',
              label: 'Own Vehicle',
              qlik: measures.potentie.totaal
            },
            {
              value: 'measures.potentie.commercieel',
              label: 'Commercial',
              qlik: measures.potentie.commercieel
            },
            {
              value: 'measures.potentie.operationeel',
              label: 'Operational',
              qlik: measures.potentie.operationeel
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              value: 'measures.km.totaal',
              label: 'Total',
              qlik: measures.km.km,
              help: 'Geeft de totale kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.km.full',
              label: 'Full',
              qlik: measures.km.kmfull,
              help: 'Geeft de beladen kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.km.omzetperkm',
              label: 'TO / KM',
              qlik: measures.km.omzetperkm,
              help: 'Geeft de omzet per kilometer weer. Hierbij wordt enkel de goedgekeurde omzet afgezet tegen de goedgekeurde kilometers'
            },
            {
              value: 'measures.km.omzetperkmfull',
              label: 'TO / Full - KM',
              qlik: measures.km.omzetperkmfull,
              help: 'Geeft de omzet per kilometer weer. Hierbij wordt enkel de goedgekeurde omzet afgezet tegen de goedgekeurde kilometers'
            },
            {
              value: 'measures.km.normomzetperkm',
              label: 'Norm TO / KM',
              qlik: measures.km.normomzetperkm,
              help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.'
            },
            {
              value: 'measures.km.normomzetperkmfull',
              label: 'Norm TO / Full - KM',
              qlik: measures.km.normomzetperkmfull,
              help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.'
            },
          ]
        },
        {
          value: 'uren',
          label: 'Hours',
          children: [
            {
              value: 'measures.uren.totaal',
              label: 'Total (Σ)',
              qlik: measures.uren.totaal,
              help: 'Het totaal aantal uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.loading',
              label: 'Loading (Σ)',
              qlik: measures.uren.loading,
              help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.unloading',
              label: 'Unloading (Σ)',
              qlik: measures.uren.unloading, 
              help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              value: 'measures.uren.driving',
              label: 'Drive (Σ)',
              qlik: measures.uren.driving,
              help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              value: 'measures.uren.other',
              label: 'Other (Σ)',
              qlik: measures.uren.other,
              help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_totaal',
              label: 'Total (avg)',
              qlik: measures.uren.avg_totaal,
              help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_loading',
              label: 'Loading (avg)',
              qlik: measures.uren.avg_loading,
              help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_unloading',
              label: 'Unloading (avg)',
              qlik: measures.uren.avg_unloading,
              help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_driving',
              label: 'Drive (avg)',
              qlik: measures.uren.avg_driving,
              help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_other',
              label: 'Other (avg)',
              qlik: measures.uren.avg_other,
              help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            }
          ]
        },
        {
          value: 'vergelijkperiode',
          label: 'Previous Year',
          children: [
            {
              value: 'measures.previousyear.omzet',
              label: 'Turnover',
              qlik: measures.previousyear.omzet
            },
            {
              value: 'measures.previousyear.orders',
              label: 'Jobs',
              qlik: measures.previousyear.orders
            },
            {
              value: 'measures.previousyear.ritten',
              label: 'Trips',
              qlik: measures.previousyear.ritten
            },
            {
              value: 'measures.previousyear.goedkeur',
              label: 'OK',
              qlik: measures.previousyear.goedkeur
            },
  
            {
              value: 'measures.previousyear.normmarge',
              label: 'Norm Margin %',
              qlik: measures.previousyear.normmarge
            },
            {
              value: 'measures.previousyear.normmargeomzet',
              label: 'Norm Margin',
              qlik: measures.previousyear.normmargeomzet
            },
            {
              value: 'measures.previousyear.omzetperuur',
              label: 'TO / Hour',
              qlik: measures.previousyear.omzetperuur
            },
            {
              value: 'measures.previousyear.uitbesteed',
              label: '% Charter',
              qlik: measures.previousyear.uitbesteed
            },
            {
              value: 'measures.previousyear.chargermarge',
              label: 'Charter Margin',
              qlik: measures.previousyear.chargermarge
            },

            {
              value: 'zendinggrootte',
              label: 'Size',
              children: [
            {
              value: 'measures.previousyear.avg_lm',
              label: 'Gemiddeld LM',
              qlik: measures.previousyear.avg_lm
            },
            {
              value: 'measures.previousyear.lm',
              label: 'Totaal LM',
              qlik: measures.previousyear.lm
            },
          ],
            },
           
          ],
        },
        { 
          value: 'persize',
          label: 'Shipmentsize',
          children: [
            {
              value: 'lm',
              label: 'LM',
              children: [
                {
                  value: 'measures.persize.lm',
                  label: 'Total',
                  qlik: measures.persize.lm,
                  help: 'Het totaal aantal laadmeters vanuit Transpas'
                },
                {
                  value: 'measures.persize.avg_lm',
                  label: 'Average',
                  qlik: measures.persize.avg_lm,
                  help: 'Het gemiddelde aantal laadmeters per zending vanuit Transpas',
                },
              ]
            },
            {
              value: 'zendinggrootte',
              label: 'Calculated LM',
              children: [
                {
                  value: 'measures.persize.shipmentsize',
                  label: 'Total',
                  qlik: measures.persize.shipmentsize,
                  help: 'Het totaal aantal berekende laadmeters. Waarbij M3 / KG / PP is omgerekend naar KG.'
                },
                {
                  value: 'measures.persize.omzet_shipmentsize',
                  label: 'TO / LM',
                  qlik: measures.persize.omzet_shipmentsize,
                  help: 'De totale omzet / gedeeld door het totale aantal berekende LM.'
                },
                {
                  value: 'measures.persize.loading_shipmentsize',
                  label: 'Loading / LM',
                  qlik: measures.persize.loading_shipmentsize,
                  help: 'Het aantal laadminuten per berekende LM. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  value: 'measures.persize.unloading_shipmentsize',
                  label: 'Lossen / LM',
                  qlik: measures.persize.unloading_shipmentsize,
                  help: 'Het aantal losminuten per berekende LM. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  value: 'measures.persize.time_shipmentsize',
                  label: 'Total Duration / LM',
                  qlik: measures.persize.time_shipmentsize,
                  help: 'Het aantal minuten per berekende LM. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
              ]
            },
          ]
        },
      ]
    }
  ];
  