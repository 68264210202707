import {dimensions, measures} from './qlik/qlik';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            qlik: dimensions.driver.name,
            label: 'Naam',
            value: 'vD_Driver.Name'
          },
          {
            qlik: dimensions.driver.number, 
            label: 'Nummer',
            value: 'vD_Driver.Number'
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            qlik: dimensions.vehicle.number,
            label: 'Nummer',
            value: 'vD_Vehicle.Number'
          },
          {
            qlik: dimensions.vehicle.license,
            label: 'Kenteken',
            value: 'vD_Vehicle.License'
          }
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            qlik: dimensions.charter.name,
            label: 'Naam',
            value: 'vD_Trip.Creditor.Name'
          },
          {
            qlik: dimensions.charter.number,
            label: 'Nummer',
            value: 'vD_Trip.Creditor.Number'
          }
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            qlik: dimensions.trailer.number, 
            label: 'Nummer',
            value: 'vD_Trip.Trailer.Number'
          }
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: dimensions.trip.number, 
            value: 'vD_Trip.TripNumber',
            label: 'Ritnummer'
          },
          {
            qlik: dimensions.trip.plangroup, 
            value: 'vD_Trip.PlanGroup',
            label: 'Afdeling'
          },
          {
            qlik: dimensions.trip.resourcecombination, 
            value: 'vD_Trip.ResourceCombination',
            label: 'Technisch ritnummer'
          },
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            qlik: dimensions.startlocation.id, 
            value: 'vD_Trip.StartLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.startlocation.name, 
            value: 'vD_Trip.StartLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.startlocation.zipcode,
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.startlocation.city, 
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.startlocation.country,
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land'
          },
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            qlik: dimensions.endlocation.id,
            value: 'vD_Trip.EndLocation.ID',
            label: 'ID'
          },
          {
            qlik: dimensions.endlocation.name,
            value: 'vD_Trip.EndLocation.Name',
            label: 'Naam'
          },
          {
            qlik: dimensions.endlocation.zipcode,
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            qlik: dimensions.endlocation.city, 
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats'
          },
          {
            qlik: dimensions.endlocation.country,
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land'
          }
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: dimensions.period.year,
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            qlik: dimensions.period.quarter,
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: dimensions.period.month,
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: dimensions.period.week,
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: dimensions.period.date,
            value: 'vD_Date',
            label: 'Datum'
          }
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal,
            value: 'vE_TripOmzet',
            label: 'Totaal',
            
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal,
            value: 'vE_TripKosten',
            label: 'Totaal',
            help: 'Totaal van de directe kosten.'
          },
          {
            qlik: measures.kosten.charter,
            value: 'vE_TripKostenCharter',
            label: 'Derden',
            help: 'Kosten van zowel Italië charters (Excel) en expeditie (Winsped).'
          },
          {
            qlik: measures.kosten.rail,
            value: 'vE_TripKostenRailFerry',
            label: 'Trein',
            help: 'Voorgecalculeerde kosten van treintrajecten uit de tarieventabel van Winsped'
          },
          {
            qlik: measures.kosten.toll,
            value: 'vE_TripKostenTol',
            label: 'Tol',
            help:'Kosten van tol uit Winsped.',
            
          },
          {
            qlik: measures.kosten.collect,
            value: 'vE_TripKostenCleaning',
            label: 'Cleaning',
            help:'Cleaning kosten uit Winsped, mochten die er niet zijn en er wel een cleaning gepland is wordt er met een standaard tarief gerekend.'
          },
          {
            qlik: measures.kosten.warehouse,
            value: 'vE_TripKostenWarehouse',
            label: 'Containerkosten',
            help: 'Voorgecalculeerde containerkosten die berekend worden door een tarief per dag te vermenigvuldigen met de dagen die een container in gebruik is. De container wordt gekoppeld aan een order na de laadactie van de order. De kosten lopen door op de order zo lang er geen nieuwe order geladen is. Eventuele hoge containerkosten op order niveau kan er op wijzen dat de container langere tijd niet geladen is.'
          },
          {
            qlik: measures.kosten.other,
            value: 'vE_TripKostenOther',
            label: 'Overig',
            help: 'Overige kosten uit Winsped'
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.orders,
            value: 'vE_TripAantalOrders',
            label: 'Zendingen',
            help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'

          },
          {
            qlik: measures.aantal.ritten,
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
            help: 'Het aantal ritten behorend bij de geselecteerde ritten. Hier zien we altijd het totaal. '

          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur,
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK',
            help: 'Het percentage ritten dat goedgekeurd is. Wanneer er iets misgaat met een rit wordt deze niet meegenomen in de kengetallen.'

          },

          {
            qlik: measures.kpi.normmarginpercent,
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %',
            help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'

          },
          {
            qlik: measures.kpi.normmarginomzet,
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge',
            help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: measures.own.omzetperuur,
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur',
            help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
          },
          {
            qlik: measures.own.bezetting,
            value: 'vE_KPI_TripBezetting',
            label: 'Bezetting'
          },
          {
            qlik: measures.own.normomzetperuur,
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '

          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed',
            help: 'Het percentage ritten dat is uitbesteed.'

          },
          {
            qlik: measures.outsourced.marge,
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge',
            help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de rit goedgekeurd is. '

          },
          {
            qlik: measures.outsourced.norm,
            value: 'vE_TripNormCharter',
            label: 'Norm',
            help: 'De geconfigureerde norm op ritniveau voor het uitbestede werk. De Derden Marge wordt getoetst aan deze norm. '

          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            qlik: measures.km.km,
            value: 'vE_TripKM',
            label: 'Totaal',
            help: 'Geeft de totale kilometers op ritniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.km.kmfull,
            value: 'vE_TripKMFull',
            label: 'Beladen',
            help: 'Geeft de beladen kilometers op ritniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.km.kmempty,
            value: 'vE_TripKMEmpty',
            label: 'Onbeladen',
            help: 'Geeft de onbeladen kilometers op ritniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.km.omzetperkm,
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            qlik: measures.km.omzetperkmfull,
            value: 'vE_KPI_TripOmzetPerKMFull',
            label: 'Omzet / beladen KM'
          },
          {
            qlik: measures.km.belading,
            value: 'vE_KPI_TripBelading',
            label: '% Beladen'
          },
          {
            qlik: measures.km.normomzetperkm,
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            help: 'De geconfigureerde norm op ritniveau voor de Omzet / KM.'

          },
          {
            qlik: measures.km.normomzetperkmfull,
            value: 'vE_TripNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            help: 'De geconfigureerde norm op ritniveau voor de Omzet / beladen KM.'

          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            qlik: measures.uren.totaal,
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
            help: 'Het totaal aantal uren op ritniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.loading,
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
            help: 'Het totaal aantal laaduren op ritniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.uren.unloading,
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
            help: 'Het totaal aantal losuren op ritniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.driving,
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
            help: 'Het totaal aantal rijuren op ritniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.other,
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
            help: 'Het totaal aantal overige uren op ritniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_totaal,
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
            help: 'Het gemiddeld aantal uren per rit uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_loading,
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
            help: 'Het gemiddeld aantal laaduren per rit uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_unloading,
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
            help: 'Het gemiddeld aantal losuren per rit uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.uren.avg_driving,
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
            help: 'Het gemiddeld aantal rijuren per rit uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_other,
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)', 
            help: 'Het gemiddeld aantal overige uren per rit uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            qlik: measures.previousyear.omzet,
            value: 'vE_TripOmzetVorigJaar',
            label: 'Omzet',
          },
          {
            qlik: measures.previousyear.orders,
            value: 'vE_TripAantalOrdersVorigJaar',
            label: 'Zendingen',
          },
          {
            qlik: measures.previousyear.ritten,
            value: 'vE_TripAantalRittenVorigJaar',
            label: 'Ritten',
          },
          {
            qlik: measures.previousyear.goedkeur,
            value: 'vE_KPI_TripGoedkeurVorigJaar',
            label: 'OK'
          },

          {
            qlik: measures.previousyear.normmarge,
            value: 'vE_KPI_TripNormMarginPercentVorigJaar',
            label: 'Norm Marge %'
          },
          {
            qlik: measures.previousyear.normmargeomzet,
            value: 'vE_KPI_TripNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge'
          },
          {
            qlik: measures.previousyear.omzetperuur,
            value: 'vE_KPI_TripOmzetPerUurVorigJaar',
            label: 'Omzet / Uur'
          },
          {
            qlik: measures.previousyear.uitbesteed,
            value: 'vE_KPI_TripTripOutsourcedVorigJaar',
            label: '% Uitbesteed'
          },
          {
            qlik: measures.previousyear.chargermarge,
            value: 'vE_KPI_TripCharterMargeVorigJaar',
            label: 'Derden Marge'
          },

        ],
      },
    ]
  }
];
