import {measures} from './qlik/qlik';
import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions } from '../orders/qlik/klant/klant';
import {dimensions as jks_dimensions} from './qlik/jks';


export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            qlik: orderdimensions.order.ordernumberdisplay,
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Order Nummer'
          }, 
          {
            qlik: jks_dimensions.order.dossier,
            value: 'vD_^JKS.Order.Dossier',
            label: 'Dossier'
          },
          {
            label: 'Klant',
            value: 'klant',
            children: [
              {
                qlik: orderdimensions.debtor.name,
                label: 'Naam',
                value: 'vD_Order.Debtor.Name'
              },
              {
                qlik: orderdimensions.debtor.number,
                label: 'Nummer',
                value: 'vD_Order.Debtor.Number'
              }
            ]
          },
          {
            label: 'Laad Locatie',
            value: 'laadlocatie',
            children: [
              {
                qlik: orderdimensions.loadinglocation.id,
                value: 'vD_Order.LoadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.loadinglocation.name,
                value: 'vD_Order.LoadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.loadinglocation.zipcode,
                value: 'vD_Order.LoadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.loadinglocation.city,
                value: 'vD_Order.LoadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.loadinglocation.country,
                value: 'vD_Order.LoadingLocation.Country',
                label: 'Land'
              },
              {
                qlik: orderdimensions.loadinglocation.district,
                value: 'vD_Order.LoadingLocation.District',
                label: 'District'
              },
              {
                qlik: orderdimensions.loadinglocation.province,
                value: 'vD_Order.LoadingLocation.Province',
                label: 'Provincie'
              },
            ]
          },
          {
            label: 'Los Locatie',
            value: 'loslocatie',
            children: [
              {
                qlik: orderdimensions.unloadinglocation.id,
                value: 'vD_Order.UnloadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.unloadinglocation.name,
                value: 'vD_Order.UnloadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.unloadinglocation.zipcode,
                value: 'vD_Order.UnloadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.unloadinglocation.city,
                value: 'vD_Order.UnloadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.unloadinglocation.country,
                value: 'vD_Order.UnloadingLocation.Country',
                label: 'Land'
              },
              {
                qlik: orderdimensions.unloadinglocation.district,
                value: 'vD_Order.UnloadingLocation.District',
                label: 'District'
              },
              {
                qlik: orderdimensions.unloadinglocation.province,
                value: 'vD_Order.UnloadingLocation.Province',
                label: 'Provincie'
              },
            ]
          },
          {
            label: 'Start traject Locatie',
            value: 'start trajectlocatie',
            children: [
              {
                qlik: jks_dimensions.traject.loadingfregiocode,
                value: 'OrderTrip.Loading.FinancialRegion',
                label: 'Laad F-Regio Code'
              },
              {
                qlik: jks_dimensions.traject.loadingfregioomschrijving,
                value: 'OrderTrip.Loading.FinancialRegionDescription',
                label: 'Laad F-Regio Omschrijving'
              },
              {
                qlik: jks_dimensions.traject.loadingoregiocode,
                value: 'OrderTrip.Loading.OperationalRegion',
                label: 'Laad O-Regio Code'
              },
              {
                qlik: jks_dimensions.traject.loadingoregioomschrijving,
                value: 'OrderTrip.Loading.OperationalRegionDescription',
                label: 'Laad O-Regio Omschrijving'
              },
              
            ]
          },
          {
            label: 'Eind traject Locatie',
            value: 'eind trajectlocatie',
            children: [
              {
                qlik: jks_dimensions.traject.unloadingfregiocode,
                value: 'OrderTrip.Unloading.FinancialRegion',
                label: 'Los F-Regio Code'
              },
              {
                qlik: jks_dimensions.traject.unloadingfregioomschrijving,
                value: 'OrderTrip.Unloading.FinancialRegionDescription',
                label: 'Los F-Regio Omschrijving'
              },
              {
                qlik: jks_dimensions.traject.unloadingoregiocode,
                value: 'OrderTrip.Unloading.OperationalRegion',
                label: 'Los O-Regio Code'
              },
              {
                qlik: jks_dimensions.traject.unloadingoregioomschrijving,
                value: 'OrderTrip.Unloading.OperationalRegionDescription',
                label: 'Los O-Regio Omschrijving'
              },
              
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: ritdimensions.trip.number,
            value: 'vD_Trip.TripNumber',
            label: 'Technisch Trip Nummer'
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                qlik: ritdimensions.driver.name,
                label: 'Naam',
                value: 'vD_Driver.Name'
              },
              {
                qlik: ritdimensions.driver.number,
                label: 'Nummer',
                value: 'vD_Driver.Number'
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                qlik: ritdimensions.vehicle.number,
                label: 'Naam',
                value: 'vD_Vehicle.Number'
              },
              {
                qlik: ritdimensions.vehicle.name,
                label: 'Nummer',
                value: 'vD_Vehicle.Name'
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                qlik: ritdimensions.charter.name,
                label: 'Naam',
                value: 'vD_Trip.CreditorName'
              },
              {
                qlik: ritdimensions.charter.number,
                label: 'Nummer',
                value: 'vD_Trip.CreditorNumber'
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                qlik: ritdimensions.trailer.number,
                label: 'Nummer',
                value: 'vD_Trip.Trailer.Number'
              },
            ]
          },

        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: ritdimensions.period.year,
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            qlik: ritdimensions.period.quarter,
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: ritdimensions.period.month,
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: ritdimensions.period.week,
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: ritdimensions.period.date,
            value: 'vD_Date',
            label: 'Datum'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal,
            value: 'vE_OrderTripOmzet',
            label: 'Totaal'
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal,
            value: 'vE_OrderTripKosten',
            label: 'Totaal',
            help: 'Totaal van de directe ritkosten. De kosten zijn toebedeeld vanuit de rit naar een traject (de combinatie tussen order en rit).'
          },
          {
            qlik: measures.kosten.charter,
            value: 'vE_OrderTripKostenCharter',
            label: 'Derden',
            help: 'Charter kosten van uitbestede ritten, dit zijn de geboekte kosten in Plan & Go.'
          },
          {
            qlik: measures.kosten.toll,
            value: 'vE_OrderTripKostenTol',
            label: 'Tol',
            help: 'Tolkosten vanuit de tolcalculatie in Plan & Go op basis van de afstandentabel'
          },
          {
            qlik: measures.kosten.codriver,
            value: 'vE_OrderKostenCoDriver',
            label: 'Bijrijder',
            help: 'Bijrijder kosten vanuit de geboekte kosten in Plan & Go of gecalculeerd op basis van toegekende uren x uurtarief'
          },
          {
            qlik: measures.kosten.warehouse,
            value: 'vE_OrderTripKostenWarehouse',
            label: 'Crossdock',
            help: 'Voorcalculatorische kosten berekend op basis van de laadmeters x tarief'

          },
          {
            qlik: measures.kosten.rail,
            value: 'vE_TripKostenRail',
            label: 'Ferry',
            help: 'Geboekte trein kosten vanuit Plan & Go'
          },
          {
            qlik: measures.kosten.other,
            value: 'vE_OrderTripKostenOther',
            label: 'Overig',
            help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.orders,
            value: 'vE_OrderTripAantalOrders',
            label: 'Order',
            help: 'Het aantal orders, waarbij een order een unieke transportopdracht is van A naar B'

          },
          {
            qlik: measures.aantal.ritten,
            value: 'vE_OrderTripAantalRitten',
            label: 'Ritten',
            help: 'Het aantal ritten behorend bij de geselecteerde traject. Hier zien we altijd het totaal. '
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur,
            value: 'vE_KPI_OrderTripGoedkeur',
            label: 'OK',
            help: 'Het percentage orders dat goedgekeurd is. Wanneer er iets misgaat met een order wordt deze niet meegenomen in de kerngetallen.'
          },

          {
            qlik: measures.kpi.normmarginpercent,
            value: 'vE_KPI_OrderTripNormMarginPercent',
            label: 'Norm Marge %',
            help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde trajecten.'
          },
          {
            qlik: measures.kpi.normmarginomzet,
            value: 'vE_KPI_OrderTripNormMarginOverOmzet',
            label: 'Norm Marge',
            help: 'De Norm Marge interpoleert het berekende norm percentage over de gehele omzet zodat je ziet wat je marge is euro\'s.'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: measures.own.omzetperuur,
            value: 'vE_KPI_OrderTripOmzetPerUur',
            label: 'Omzet / Uur',
            help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde trajecten. '
          },
          {
            qlik: measures.own.bezetting,
            value: 'vE_KPI_OrderTripBezetting',
            label: 'Bezetting',
            help: 'De bezetting toont het percentage van inzet van het voertuig op basis van de uren uit de boordcomputer ten opzichte van de verwachte inzet (12 uur per werkdag). '

          },
          {
            qlik: measures.own.normomzetperuur,
            value: 'vE_OrderTripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            help: 'De geconfigureerde norm op orderniveau voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            qlik: measures.outsourced.marge,
            value: 'vE_KPI_OrderTripCharterMarge',
            label: 'Derden Marge'
          },
          {
            qlik: measures.outsourced.norm,
            value: 'vE_OrderTripNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            qlik: measures.km.km,
            value: 'vE_OrderTripKM',
            label: 'Totaal'
          },
          {
            qlik: measures.km.kmfull,
            value: 'vE_OrderTripKMFull',
            label: 'Beladen'
          },
          {
            qlik: measures.km.kmempty,
            value: 'vE_OrderTripKMEmpty',
            label: 'Onbeladen'
          },
          {
            qlik: measures.km.omzetperkm,
            value: 'vE_KPI_OrderTripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            qlik: measures.km.omzetperkmfull,
            value: 'vE_KPI_OrderTripOmzetPerKMFull',
            label: 'Omzet / beladen KM'
          },
          {
            qlik: measures.km.belading,
            value: 'vE_KPI_OrderTripBelading',
            label: '% Beladen'
          },
          {
            qlik: measures.km.normomzetperkm,
            value: 'vE_OrderTripNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
          {
            qlik: measures.km.normomzetperkmfull,
            value: 'vE_OrderTripNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM'
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            qlik: measures.uren.totaal,
            value: 'vE_OrderTripUren',
            label: 'Totaal (Σ)',
          },
          {
            qlik: measures.uren.loading,
            value: 'vE_OrderTripUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            qlik: measures.uren.unloading,
            value: 'vE_OrderTripUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            qlik: measures.uren.driving,
            value: 'vE_OrderTripUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            qlik: measures.uren.other,
            value: 'vE_OrderTripUrenOther',
            label: 'Overig (Σ)',
          },
          {
            qlik: measures.uren.cleaning,
            value: 'vE_OrderTripUrenCleaning',
            label: 'Spoelen (Σ)',
          },
          {
            qlik: measures.uren.avg_totaal,
            value: 'vE_OrderTripUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            qlik: measures.uren.avg_loading,
            value: 'vE_OrderTripUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            qlik: measures.uren.avg_unloading,
            value: 'vE_OrderTripUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            qlik: measures.uren.avg_driving,
            value: 'vE_OrderTripUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            qlik: measures.uren.avg_other,
            value: 'vE_OrderTripUrenAvgOther',
            label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
          },
          {
            qlik: measures.uren.avg_cleaning,
            value: 'vE_OrderTripUrenAvgCleaning',
            label: 'Spoelen (avg)',
          },
        ]
      },
      {
        value: 'persize',
        label: 'Per Eenheid',
        children: [
          {
            value: 'm3',
            label: 'M3',
            children: [
              {
                qlik: measures.persize.shipmentsize,
                value: 'vE_OrderTripShipmentSize',
                label: 'M3'
              },
              {
                qlik: measures.persize.omzet_shipmentsize,
                value: 'vE_KPI_OrderTripOmzetPerZendingGroottePervE_OrderShipmentSize',
                label: 'Omzet Per M3'
              },
              {
                qlik: measures.persize.loading_shipmentsize,
                value: 'vE_KPI_OrderTripLaadMinutenPerZendingGrootte',
                label: 'Laad minuten per M3'
              },
              {
                qlik: measures.persize.unloading_shipmentsize,
                value: 'vE_KPI_OrderTripLosMinutenPerZendingGrootte',
                label: 'Los minuten per M3'
              },
              {
                qlik: measures.persize.time_shipmentsize,
                value: 'vE_KPI_OrderTripMinutenPerZendingGrootte',
                label: 'Tijd per M3'
              },
            ]
          }
        ]
      },
    ]
  }
];
