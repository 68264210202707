import orderList from './../../margemonitor/components/orders/dimensions-ABT';
import ritList from './../../margemonitor/components/ritten/dimensions-ABT';
import orderTripList from './../../margemonitor/components/trajecten/dimensions';

const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'transportcostconsult.eu.qlikcloud.com',
    //host: 'report.abtexelgroup.com',
    secure: true,
    port: 443,
    prefix: '',
    webIntegrationId: '4FinwuxlNHlaQLIQ3-eWhtY9dG090yJn',  
    theme: 'ABTexel',
    appId: localStorage.getItem('appId') ? localStorage.getItem('appId') :'717a4ebe-eb37-4925-9e02-7eae37a1f8c5',
  },
  connections: {
    spaceId: '61839be4bcc855c0b8b8863d',
    qlikCloud: true,
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Year)]'],
            placeholder: 'Jaar',
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Month)]'],
            placeholder: 'Maand',
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Quarter)]'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Weekday)]'],
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            placeholder: 'Dag',
            qSortByAscii: 1,
          }
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Week)]'],
            placeholder: 'Week',
            autoSortByState: 0,
          },
        },  
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Date)]'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.District)]'],
            placeholder: 'District',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Province)]'],
            placeholder: 'Provincie',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.District)]'],
            placeholder: 'District',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Province)]'],
            placeholder: 'Provincie',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Ordernummer',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Klant'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Order.Debtor.Name)'],
            placeholder: 'Klantnaam',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'DebiteurNummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Afdeling'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Kind.Description)]'],
            placeholder: 'Order soort',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Bedrijf)]'],
            placeholder: 'Bedrijf',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Company.Intercompany)]'],
            placeholder: 'Intercompany Bedrijf',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Technisch'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Systeem)]'],
            placeholder: 'Systeem',
            showStateInDropdown: true,
          },
        }
      ],
    },
    trip: {
      replace: false,
      filters: [
        { 
          type: 'title',
          title: "Rit" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Auto nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Autonaam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeurnummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeurnaam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[_ind_Trip.Outsourced]'],
            placeholder: 'Uitbesteed',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Chartersoort',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Crediteur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Crediteur naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Afdeling" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Company.Name)]'],
            placeholder: 'Bedrijf',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Company.Intercompany)]'],
            placeholder: 'Intercompany Bedrijf',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
        {
          title: true,
          name: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
      ],
  }, 
  routes: [
  ],
  fieldSettings:{
    period: 'vD_Month'
  },
  selectionBar: 'Capability',
  rollingPeriod: false,
  defaultSelections: [
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
  ]
};

export default options;
