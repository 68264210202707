import qlik from './qlik';
import {measures} from './qlik/qlik';
import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions } from '../orders/qlik/klant/klant';
export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            value: 'orderdimensions.order.ordernumberdisplay',
            label: 'Zending',
            qlik: orderdimensions.order.number
          }, 
          {
            label: 'Debiteur',
            value: 'klant',
            children: [
              {
                value: "orderdimensions.customer.name",
                label: "Naam",
                qlik: orderdimensions.customer.name
              },
              {
                value: "orderdimensions.customer.number",
                label: 'Nummer',
                qlik: orderdimensions.customer.number
              }
            ]
          },
          {
            label: 'Laad Locatie',
            value: 'laadlocatie',
            children: [
              {
                value: 'orderdimensions.loadinglocation.zipcode',
                label: 'Postcode',
                qlik: orderdimensions.loadinglocation.zipcode
              },
              {
                value: 'orderdimensions.loadinglocation.city',
                label: 'Plaats',
                qlik: orderdimensions.loadinglocation.city
              },
              {
                value: 'orderdimensions.loadinglocation.district',
                label: 'Gemeente',
                qlik: orderdimensions.loadinglocation.district
              },
              {
                value: 'orderdimensions.loadinglocation.province',
                label: 'Provincie',
                qlik: orderdimensions.loadinglocation.province
              },
              {
                value: 'orderdimensions.loadinglocation.country',
                label: 'Land',
                qlik: orderdimensions.loadinglocation.country
              },
              {
                value: 'orderdimensions.loadinglocation.land',
                label: 'Land (ISO)',
                qlik: orderdimensions.loadinglocation.land
              },
            ]
          },
          {
            label: 'Los Locatie',
            value: 'loslocatie',
            children: [
              {
                value: 'orderdimensions.unloadinglocation.zipcode',
                label: 'Postcode',
                qlik: orderdimensions.unloadinglocation.zipcode
              },
              {
                value: 'orderdimensions.unloadinglocation.city',
                label: 'Plaats',
                qlik: orderdimensions.unloadinglocation.city
              },
              {
                value: 'orderdimensions.unloadinglocation.district',
                label: 'Gemeente',
                qlik: orderdimensions.unloadinglocation.district
              },
              {
                value: 'orderdimensions.unloadinglocation.province',
                label: 'Provincie',
                qlik: orderdimensions.unloadinglocation.province
              },
              {
                value: 'orderdimensions.unloadinglocation.country',
                label: 'Land',
                qlik: orderdimensions.unloadinglocation.country
              },
              {
                value: 'orderdimensions.unloadinglocation.land',
                label: 'Land (ISO)',
                qlik: orderdimensions.unloadinglocation.land
              },
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            value: 'ritdimensions.trip.number',
            label: 'Rit Nummer',
            qlik: ritdimensions.trip.number
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                label: 'Naam',
                value: 'ritdimensions.driver.name',
                qlik: ritdimensions.driver.name
              },
              {
                label: 'Nummer',
                value: 'ritdimensions.driver.number',
                qlik: ritdimensions.driver.number
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                label: 'Naam',
                value: 'ritdimensions.vehicle.name',
                qlik: ritdimensions.vehicle.name
              },
              {
                label: 'Nummer',
                value: 'ritdimensions.vehicle.number',
                qlik: ritdimensions.vehicle.number
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                label: 'Naam',
                value: 'ritdimensions.charter.name',
                qlik: ritdimensions.charter.name
              },
              {
                label: 'Nummer',
                value: 'ritdimensions.charter.number',
                qlik: ritdimensions.charter.number
              }
            ]
          },
          {
            label: 'Startlocatie rit',
            value: 'Startlocatie rit',
            children: [
              {
                value: 'dimensions.startlocation.adress',
                label: 'Adres',
                qlik: ritdimensions.startlocation.adress
              },
              {
                value: 'dimensions.startlocation.city',
                label: 'Plaats',
                qlik: ritdimensions.startlocation.city
              },
              {
                value: 'dimensions.startlocation.district',
                label: 'Gemeente',
                qlik: ritdimensions.startlocation.district
              },
              {
                value: 'dimensions.startlocation.province',
                label: 'Provincie',
                qlik: ritdimensions.startlocation.province
              },
              {
                value: 'dimensions.startlocation.country',
                label: 'Land',
                qlik: ritdimensions.startlocation.country
              },
              {
                value: 'dimensions.startlocation.land',
                label: 'Land (ISO)',
                qlik: ritdimensions.startlocation.land
              }
            ]
          },
          {
            label: 'Eindlocatie rit',
            value: 'Eindlocatie rit',
            children: [
              {
                value: 'dimensions.endlocation.adress',
                label: 'Adres',
                qlik: ritdimensions.endlocation.adress
              },
              {
                value: 'dimensions.endlocation.city',
                label: 'Plaats',
                qlik: ritdimensions.endlocation.city
              },
              {
                value: 'dimensions.endlocation.district',
                label: 'Gemeente',
                qlik: ritdimensions.endlocation.district
              },
              {
                value: 'dimensions.endlocation.province',
                label: 'Provincie',
                qlik: ritdimensions.endlocation.province
              },
              {
                value: 'dimensions.endlocation.country',
                label: 'Land',
                qlik: ritdimensions.endlocation.country
              },
              {
                value: 'dimensions.endlocation.land',
                label: 'Land (ISO)',
                qlik: ritdimensions.endlocation.land
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'Trailer',
            children: [
              {
                label: 'Nummer',
                value: 'ritdimensions.trailer.number',
                qlik: ritdimensions.trailer.number
              },
            ]
          },

        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'ritdimensions.period.year',
            label: 'Jaar',
            qlik: ritdimensions.period.year
          },
          {
            value: 'ritdimensions.period.quarter',
            label: 'Kwartaal',
            qlik: ritdimensions.period.quarter
          },
          {
            value: 'ritdimensions.period.month',
            label: 'Maand',
            qlik: ritdimensions.period.month
          },
          {
            value: 'ritdimensions.period.week',
            label: 'Week',
            qlik: ritdimensions.period.week
          },
          {
            value: 'ritdimensions.period.date',
            label: 'Datum',
            qlik: ritdimensions.period.date
          },
          {
            value: 'dimensions.period.weekday',
            label: 'Dag',
            qlik: ritdimensions.period.weekday
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'measures.omzet.totaal',
            label: 'Totaal',
            qlik: measures.omzet.totaal
          },
            {
              value: "measures.omzet.freight",
              qlik: measures.omzet.freight,
              label: 'Vracht'
            },
            {
              value: "measures.omzet.fuel",
              qlik: measures.omzet.fuel,
              label: 'Brandstof'
            },
            {
              value: "measures.omzet.other",
              qlik: measures.omzet.other,
              label: 'Overig'
            },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'measures.kosten.totaal',
            label: 'Totaal',
            qlik: measures.kosten.totaal,
            help: 'Totaal van de directe kosten.'
          },
          {
            value: 'measures.kosten.charter',
            label: 'Charter', 
            qlik: measures.kosten.charter,
            help: 'Kosten van kilometer, uren en vaste charters'
          },
          {
            value: 'measures.kosten.toll',
            label: 'Tol',
            qlik: measures.kosten.toll,
            help: 'Tolkosten worden enkel vanuit Winsped meegenomen wanneer ze geregistreerd worden op een eigen auto'
          },
          {
            value: 'measures.kosten.warehouse',
            label: 'Warehouse',
            qlik: measures.kosten.warehouse,
            help: 'Voorcalculatorische kosten vanuit Winsped voor de reservering van de directe Warehouse kosten per zending'
          },
          {
            value: "measures.kosten.collect",
            qlik: measures.kosten.collect,
            label: 'Voorhaal',
            help: 'Gecalculeerde voorhaalkosten op basis van tarief x kg voor aangegeven trajecten'
          },
          {
            value: 'measures.kosten.other',
            label: 'Other',
            qlik: measures.kosten.other,
            help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'measures.aantal.orders',
            label: 'Zendingen',
            qlik: measures.aantal.orders,
            help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
          },
          {
            value: 'measures.aantal.ritten',
            label: 'Ritten',
            qlik: measures.aantal.ritten,
            help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'measures.kpi.goedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },
          {
            value: 'measures.kpi.normmarginpercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            value: 'measures.kpi.normmarginomzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet
          },
        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'measures.own.omzetperuur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur
          },
          // {
          //   value: 'measures.own.omzetperuurexfuel',
          //   label: 'Omzet / Uur zonder DOT',
          //   qlik: measures.own.omzetperuurexfuel
          // },
          {
            value: 'measures.own.omzetperuurinclfuel',
            label: 'Omzet / Uur incl DOT',
            qlik: measures.own.omzetperuurinclfuel
          },
          {
            value: 'measures.own.normomzetperuur',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'measures.outsourced.percentage',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage
          },
          {
            value: 'measures.outsourced.marge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge
          },
          {
            value: 'measures.outsourced.norm',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'measures.km.km',
            label: 'Totaal',
            qlik: measures.km.km
          },
          {
            value: 'measures.km.omzetperkm',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm
          },
          {
            value: 'measures.km.normomzetperkm',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'measures.uren.totaal',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal
          },
          {
            value: 'measures.uren.loading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading
          },
          {
            value: 'measures.uren.unloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading
          },
          {
            value: 'measures.uren.driving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving
          },
          {
            value: 'measures.uren.other',
            label: 'Overig (Σ)',
            qlik: measures.uren.other
          },
          {
            value: 'measures.uren.avg_totaal',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            value: 'measures.uren.avg_loading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading
          },
          {
            value: 'measures.uren.avg_unloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading
          },
          {
            value: 'measures.uren.avg_driving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving
          },
          {
            value: 'measures.uren.avg_other',
            label: 'Overig (avg)', 
            qlik: measures.uren.avg_other 
          },
        ]
      },
    ]
  }
];
