import orderList from './../../margemonitor/components/orders/dimensions-SIM';
import ritList from './../../margemonitor/components/ritten/dimensions-SIM';
import orderTripList from './../../margemonitor/components/trajecten/dimensions-SIM';

const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'sense.gesimons.kasper.korton.net',
    //host: 'transportcostconsult.eu.qlikcloud.com',
    secure: true,
    port: 443,
    prefix: '',
    webIntegrationId: 'UC7aldzKKXMMGqblEErpfq2xZ-LgCxNv',  
   // appId: '05df4436-f9ff-4fa7-9262-a94cf4eee5cd'
    //appId: 'deef9db0-81d8-4a4c-a473-4d6356c48de0',
    appId: 'f11488a6-3c91-4300-93fc-424135b84965',
  },
  connections: {
    legacyBookmarks: true, 
    qlikCloud: false,
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Year)]'],
            placeholder: 'Jaar',
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Quarter)]'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Month)]'],
            placeholder: 'Maand',
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Week)]'],
            placeholder: 'Week',
            autoSortByState: 0,
          },
        },  
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Date)]'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Zending',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Klant'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Order.Debtor.Name)'],
            placeholder: 'Naam',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Afdeling'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Department)]'],
            placeholder: 'Afdeling',
            showStateInDropdown: true,
          },
        }
      ],
    },
    trip: {
      replace: false,
      filters: [
        { 
          type: 'title',
          title: "Rit" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.ResourceCombination)]'],
            placeholder: 'Technisch ritnummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.PlanGroup)]'],
            placeholder: 'Plangroep',
            showStateInDropdown: true,
          },
          
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[_ind_Trip.Outsourced]'],
            placeholder: 'Uitbesteed',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
      {
        title: true,
        name: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
    ]
  },
  routes: [
  ],
  fieldSettings:{
    period: 'vD_Month'
  },
  selectionBar: 'Capability',
  rollingPeriod: false,
  defaultSelections: [
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
  ]
};

export default options;
