import qlik from './qlik';
import {measures} from './qlik/qlik';
import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions } from '../orders/qlik/klant/klant';
import { measures as orderritmeasures} from './qlik/wtr';
import { dimensions as customerorderdimensions} from '../orders/qlik/klant/wtr'

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            label: 'Opdrachtgever',
            value: 'opdrachtgever',
            children: [
              {
                label: 'Naam',
                value: 'vD_Order.Customer.Name',
                qlik: orderdimensions.customer.name
              },
              {
                label: 'Nummer',
                value: 'vD_Order.Customer.Number',
                qlik: orderdimensions.customer.number
              },
            ]
          },
          {
            label: 'Debiteur',
            value: 'debtor',
            children: [
              {
                label: 'Naam',
                value: 'vD_Order.Debtor.Name',
                qlik: orderdimensions.debtor.name
              },
              {
                label: 'Nummer',
                value: 'vD_Order.Debtor.Number',
                qlik: orderdimensions.debtor.number
              }
            ]
          },
          {
            label: 'Order',
            value: 'order',
            children: [
              {
                value: 'vD_Order.Kind',
                label: 'Soort',
                qlik: orderdimensions.order.kind
              },
              {
                value: 'vD_Order.OrderNumber',
                label: 'Technisch %Order%',
                qlik: orderdimensions.order.number
              },
              {
                value: 'vD_Order.Dossier',
                label: 'Dossier',
                qlik: orderdimensions.order.dossier
              },
              {
                value: 'vD_Order.OrderNumberDisplay',
                label: 'Zending',
                qlik: orderdimensions.order.ordernumberdisplay
              },
              {
                value: 'vD_Order.Company.Name',
                label: 'Bedrijf',
                qlik: orderdimensions.order.companyname
              },
              {
                value: 'vD_Order.Company.Code',
                label: 'Bedrijfscode',
                qlik: orderdimensions.order.companycode
              },
    
              {
                value: 'vD_Order.Reference',
                label: 'Reference',
                qlik: orderdimensions.order.reference
              },
              {
                value: 'vD_Order.PlanGroup',
                label: 'Plangroep',
                qlik: orderdimensions.order.plangroup
              },
              {
                value: 'vD_Order.LandGroup',
                label: 'Planafdeling',
                qlik: orderdimensions.order.plandivision
              },
              {
                value: 'vD_Order.Status',
                label: 'Status',
                qlik: orderdimensions.order.status
              },
              {
                value: 'vD_Order.Kind',
                label: 'Soort',
                qlik: orderdimensions.order.kind
              },
              {
                value: 'vD_Order.CostCenter.Description',
                label: 'Kostenplaats',
                qlik: customerorderdimensions.order.costcenterdescription
              },
            ]
          },
          {
            label: 'Laad Locatie',
            value: 'laadlocatie',
            children: [
              {
                value: 'vD_Order.LoadingLocation.ID',
                label: 'ID',
                qlik: orderdimensions.loadinglocation.id
              },
              {
                value: 'vD_Order.LoadingLocation.Adress',
                label: 'Adress',
                qlik: orderdimensions.loadinglocation.adress
              },
              {
                value: 'vD_Order.LoadingLocation.ZIPcode',
                label: 'Postcode',
                qlik: orderdimensions.loadinglocation.zipcode
              },
              {
                value: 'vD_Order.LoadingLocation.City',
                label: 'Plaats',
                qlik: orderdimensions.loadinglocation.city
              },
              {
                value: 'vD_Order.LoadingLocation.Country',
                label: 'Land',
                qlik: orderdimensions.loadinglocation.country
              },
              {
                value: 'vD_Order.LoadingLocation.District',
                label: 'District',
                qlik: orderdimensions.loadinglocation.district
              },
              {
                value: 'vD_Order.LoadingLocation.Province',
                label: 'Provincie',
                qlik: orderdimensions.loadinglocation.province
              },
            ]
          },
          {
            label: 'Los Locatie',
            value: 'loslocatie',
            children: [
              {
                value: 'vD_Order.UnloadingLocation.ID',
                label: 'ID',
                qlik: orderdimensions.unloadinglocation.id
              },
              {
                value: 'vD_Order.UnloadingLocation.Adress',
                label: 'Adres',
                qlik: orderdimensions.unloadinglocation.adress
              },
              {
                value: 'vD_Order.UnloadingLocation.ZIPcode',
                label: 'Postcode',
                qlik: orderdimensions.unloadinglocation.zipcode
              },
              {
                value: 'vD_Order.UnloadingLocation.City',
                label: 'Plaats',
                qlik: orderdimensions.unloadinglocation.city
              },
              {
                value: 'vD_Order.UnloadingLocation.Country',
                label: 'Land',
                qlik: orderdimensions.unloadinglocation.country
              },
              {
                value: 'vD_Order.UnloadingLocation.District',
                label: 'District',
                qlik: orderdimensions.unloadinglocation.district
              },
              {
                value: 'vD_Order.UnloadingLocation.Province',
                label: 'Provincie',
                qlik: orderdimensions.unloadinglocation.province
              },
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            value: 'vD_Trip.TripNumber',
            label: 'Trip Nummer',
            qlik: ritdimensions.trip.number
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                label: 'Naam',
                value: 'vD_Driver.Name',
                qlik: ritdimensions.driver.name
              },
              {
                label: 'Nummer',
                value: 'vD_Driver.Number',
                qlik: ritdimensions.driver.number
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                label: 'Naam',
                value: 'vD_Vehicle.Number',
                qlik: ritdimensions.vehicle.number
              },
              {
                label: 'Nummer',
                value: 'vD_Vehicle.Name',
                qlik: ritdimensions.vehicle.name
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                label: 'Naam',
                value: 'vD_Trip.CreditorName',
                qlik: ritdimensions.charter.name
              },
              {
                label: 'Nummer',
                value: 'vD_Trip.CreditorNumber',
                qlik: ritdimensions.charter.number
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                label: 'Name',
                value: 'vD_Trip.Trailer.Name',
                qlik: ritdimensions.trailer.name
              },
              {
                label: 'Nummer',
                value: 'vD_Trip.Trailer.Number',
                qlik: ritdimensions.trailer.number
              },
            ]
          },

        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar',
            qlik: ritdimensions.period.year
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal',
            qlik: ritdimensions.period.quarter
          },
          {
            value: 'vD_Month',
            label: 'Maand',
            qlik: ritdimensions.period.month
          },
          {
            value: 'vD_Week',
            label: 'Week',
            qlik: ritdimensions.period.week
          },
          {
            value: 'vD_Date',
            label: 'Datum',
            qlik: ritdimensions.period.date
          },
          {
            value: 'vD_Weekday',
            label: 'Dag',
            qlik: ritdimensions.period.weekday
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_OrderTripOmzet',
            label: 'Totaal',            
            qlik: measures.omzet.totaal
          },
          {
            value: 'vE_OrderTripOmzet.Freight',
            label: 'Vracht',
            help: 'Vrachtkosten vanuit Transpas',
            qlik: measures.omzet.freight
          },
          {
            value: 'vE_OrderTripOmzet.Fuel',
            label: 'Brandstof',
            help: 'Brandstoftoeslagen zoals deze berekend worden op zendingniveau in Transpas',
            qlik: measures.omzet.fuel
          },
          {
            value: 'vE_OrderTripOmzet.Maut',
            label: 'Tol',
            help: 'Tol zoals deze berekend worden op zendingniveau in Transpas',
            qlik: measures.omzet.toll
          },
          {
            value: 'vE_OrderTripOmzet.Other',
            label: 'Overig',
            help: 'Overige toeslagen',
            qlik: measures.omzet.other
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_OrderTripKosten',
            label: 'Totaal',
            qlik: measures.kosten.totaal,
            help: 'Totaal van de directe kosten.'
          },
          {
            value: 'vE_OrderTripKostenCharter',
            label: 'Derden', 
            qlik: measures.kosten.charter,
            help: 'Kosten van kilometer, uren en vaste charters'
          },
          {
            value: 'vE_OrderTripKostenRailFerry',
            label: 'Trein', 
            qlik: measures.kosten.rail,
            help: 'Trein kosten vanuit Transpas'
          },
          {
            value: 'vE_OrderTripKostenTol',
            label: 'Tol', 
            qlik: measures.kosten.toll,
            help: 'Tolkosten vanuit Transpas'
          },
          {
            value: 'vE_OrderTripKostenJIB',
            label: 'JIB', 
            qlik: orderritmeasures.kosten.jib,
            help: 'Tolkosten vanuit Transpas'
          },
          {
            value: 'vE_OrderTripKostenOther',
            label: 'Overig',
            qlik: measures.kosten.other,
            help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'

          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_OrderTripAantalDossiers',
            label: 'Dossiers',
            qlik: measures.aantal.dossiers,
            help: 'Het aantal dossiers, waarbij meerdere zendingen gecombineerd worden'
          },
          {
            value: 'vE_OrderTripAantalOrders',
            label: 'Zendingen',
            qlik: measures.aantal.orders,
            help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
          },
          {
            value: 'vE_OrderTripAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten,
            help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_OrderTripGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },
          {
            value: 'vE_KPI_OrderTripNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            value: 'vE_KPI_OrderTripNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet
          },
        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_OrderTripOmzetPerUur',
            label: 'Omzet / Uur',
            qlik: measures.own.omzetperuur
          },
          {
            value: 'vE_KPI_OrderTripBezetting',
            label: 'Bezetting',
            qlik: measures.own.bezetting
          },
          {
            value: 'vE_OrderTripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage
          },
          {
            value: 'vE_KPI_OrderTripCharterMarge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge
          },
          {
            value: 'vE_OrderTripNormCharter',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_OrderTripKM',
            label: 'Totaal',
            qlik: measures.km.km
          },
          {
            value: 'vE_OrderTripKMFull',
            label: 'Beladen',
            qlik: measures.km.kmfull
          },
          {
            value: 'vE_OrderTripKMEmpty',
            label: 'Onbeladen',
            qlik: measures.km.kmempty
          },
          {
            value: 'vE_KPI_OrderTripOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm
          },
          {
            value: 'vE_KPI_OrderTripOmzetPerKMFull',
            label: 'Omzet / beladen KM',
            qlik: measures.km.omzetperkmfull
          },
          {
            value: 'vE_KPI_OrderTripBelading',
            label: '% Beladen',
            qlik: measures.km.kmfull
          },
          {
            value: 'vE_OrderTripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm
          },
          {
            value: 'vE_OrderTripNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            qlik: measures.km.normomzetperkmfull
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_OrderTripUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal
          },
          {
            value: 'vE_OrderTripUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading
          },
          {
            value: 'vE_OrderTripUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading
          },
          {
            value: 'vE_OrderTripUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving
          },
          {
            value: 'vE_OrderTripUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other
          },
          {
            value: 'vE_OrderTripUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            value: 'vE_OrderTripUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading
          },
          {
            value: 'vE_OrderTripUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading
          },
          {
            value: 'vE_OrderTripUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving
          },
          {
            value: 'vE_OrderTripUrenAvgOther',
            label: 'Overig (avg)',
            qlik: measures.uren.avg_other
          },
        ]
      },
    ]
  }
];
