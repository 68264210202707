const dimensions = {
    debtor: {
      name: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.DebiteurNaam)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.DebiteurNaam)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      number: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.DebiteurNummer)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.DebiteurNummer)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
    },
    order: {
      number: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.OrderNummer)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.OrderNummer)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
    },
};
const measures = {
  aantal: {
    rittenmetcodriver: {
      "qLibraryId": "",
      "qDef": {
        "qLabel": "",
        "qDescription": "",
        "qTags": [],
        "qGrouping": "N",
        "qDef": "$(vE_OrderAantalRitten(Trip.NrOfCoDriver={'1'}))",
        "qNumFormat": {
          "qType": "U",
          "qnDec": 10,
          "qUseThou": 0,
          "qFmt": "",
          "qDec": "",
          "qThou": ""
        },
        "qRelative": false,
        "qBrutalSum": false,
        "qAggrFunc": "Expr",
        "qAccumulate": 0,
        "qReverseSort": false,
        "qActiveExpression": 0,
        "qExpressions": [],
        "qLabelExpression": "='$(vL_OrderAantalRitten)' & ' > ' & 'Met bijrijder'",
        "autoSort": true,
        "cId": "eUNc",
        "numFormatFromTemplate": true,
        "textAlign": {
          "auto": true,
          "align": "left"
        }
      },
      "qSortBy": {
        "qSortByState": 0,
        "qSortByFrequency": 0,
        "qSortByNumeric": -1,
        "qSortByAscii": 0,
        "qSortByLoadOrder": 1,
        "qSortByExpression": 0,
        "qExpression": {
          "qv": ""
        },
        "qSortByGreyness": 0
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCond": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    rittenzondercodriver: {
      "qLibraryId": "",
      "qDef": {
        "qLabel": "",
        "qDescription": "",
        "qTags": [],
        "qGrouping": "N",
        "qDef": "$(vE_OrderAantalRitten(Trip.NrOfCoDriver={'0'}))",
        "qNumFormat": {
          "qType": "U",
          "qnDec": 10,
          "qUseThou": 0,
          "qFmt": "",
          "qDec": "",
          "qThou": ""
        },
        "qRelative": false,
        "qBrutalSum": false,
        "qAggrFunc": "Expr",
        "qAccumulate": 0,
        "qReverseSort": false,
        "qActiveExpression": 0,
        "qExpressions": [],
        "qLabelExpression": "='$(vL_OrderAantalRitten)' & ' > ' & 'Zonder bijrijder'",
        "autoSort": true,
        "cId": "eUNc",
        "numFormatFromTemplate": true,
        "textAlign": {
          "auto": true,
          "align": "left"
        }
      },
      "qSortBy": {
        "qSortByState": 0,
        "qSortByFrequency": 0,
        "qSortByNumeric": -1,
        "qSortByAscii": 0,
        "qSortByLoadOrder": 1,
        "qSortByExpression": 0,
        "qExpression": {
          "qv": ""
        },
        "qSortByGreyness": 0
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCond": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
  },
  previousyear: {
    rittenmetcodriver: {
      "qLibraryId": "",
      "qDef": {
        "qLabel": "",
        "qDescription": "",
        "qTags": [],
        "qGrouping": "N",
        "qDef": "$(vE_OrderAantalRitten(Trip.NrOfCoDriver={'1'},$(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
        "qNumFormat": {
          "qType": "U",
          "qnDec": 10,
          "qUseThou": 0,
          "qFmt": "",
          "qDec": "",
          "qThou": ""
        },
        "qRelative": false,
        "qBrutalSum": false,
        "qAggrFunc": "Expr",
        "qAccumulate": 0,
        "qReverseSort": false,
        "qActiveExpression": 0,
        "qExpressions": [],
        "qLabelExpression": "='$(vL_OrderAantalRitten)' & ' > ' & 'Met bijrijder '& (only($(vD_Year)) - 1)",
        "autoSort": true,
        "cId": "eUNc",
        "numFormatFromTemplate": true,
        "textAlign": {
          "auto": true,
          "align": "left"
        }
      },
      "qSortBy": {
        "qSortByState": 0,
        "qSortByFrequency": 0,
        "qSortByNumeric": -1,
        "qSortByAscii": 0,
        "qSortByLoadOrder": 1,
        "qSortByExpression": 0,
        "qExpression": {
          "qv": ""
        },
        "qSortByGreyness": 0
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCond": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    rittenzondercodriver: {
      "qLibraryId": "",
      "qDef": {
        "qLabel": "",
        "qDescription": "",
        "qTags": [],
        "qGrouping": "N",
        "qDef": "$(vE_OrderAantalRitten(Trip.NrOfCoDriver={'0'},$(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
        "qNumFormat": {
          "qType": "U",
          "qnDec": 10,
          "qUseThou": 0,
          "qFmt": "",
          "qDec": "",
          "qThou": ""
        },
        "qRelative": false,
        "qBrutalSum": false,
        "qAggrFunc": "Expr",
        "qAccumulate": 0,
        "qReverseSort": false,
        "qActiveExpression": 0,
        "qExpressions": [],
        "qLabelExpression": "='$(vL_OrderAantalRitten)' & ' > ' & 'Zonder bijrijder '& (only($(vD_Year)) - 1)",
        "autoSort": true,
        "cId": "eUNc",
        "numFormatFromTemplate": true,
        "textAlign": {
          "auto": true,
          "align": "left"
        }
      },
      "qSortBy": {
        "qSortByState": 0,
        "qSortByFrequency": 0,
        "qSortByNumeric": -1,
        "qSortByAscii": 0,
        "qSortByLoadOrder": 1,
        "qSortByExpression": 0,
        "qExpression": {
          "qv": ""
        },
        "qSortByGreyness": 0
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCond": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    tripomzetperuur: {
      "qLibraryId": "",
      "qDef": {
        "qLabel": "",
        "qDescription": "",
        "qTags": [],
        "qGrouping": "N",
        "qDef": "$(vE_KPI_Order_TripOmzetPerUur($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
        "qNumFormat": {
          "qType": "U",
          "qnDec": 10,
          "qUseThou": 0,
          "qFmt": "",
          "qDec": "",
          "qThou": ""
        },
        "qRelative": false,
        "qBrutalSum": false,
        "qAggrFunc": "Expr",
        "qAccumulate": 0,
        "qReverseSort": false,
        "qActiveExpression": 0,
        "qExpressions": [],
        "qLabelExpression": "='$(vL_KPI_Order_TripOmzetPerUur) ' & (only($(vD_Year)) - 1)",
        "autoSort": true,
        "numFormatFromTemplate": true,
        "textAlign": {
          "auto": true,
          "align": "left"
        }
      },
      "qSortBy": {
        "qSortByState": 0,
        "qSortByFrequency": 0,
        "qSortByNumeric": -1,
        "qSortByAscii": 0,
        "qSortByLoadOrder": 1,
        "qSortByExpression": 0,
        "qExpression": {
          "qv": ""
        },
        "qSortByGreyness": 0
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCond": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
  }
};

export {measures, dimensions};