const dimensions = {
  debtor: {
    group: {
        "qDef": {
            "qGrouping": "N",
            "qFieldDefs": [
                "[$(vD_Order.Debtor.Group)]"
            ],
            "qSortCriterias": [
                {
                    "qSortByState": 0,
                    "qSortByFrequency": 0,
                    "qSortByNumeric": 1,
                    "qSortByAscii": 1,
                    "qSortByLoadOrder": 1,
                    "qSortByExpression": 0,
                    "qExpression": {
                        "qv": ""
                    },
                    "qSortByGreyness": 0
                }
            ],
            "qNumberPresentations": [],
            "qReverseSort": false,
            "qActiveField": 0,
            "qLabelExpression": "='$(vD_Order.Debtor.Group)'",
            "autoSort": true,
            "othersLabel": "Overige",
            "textAlign": {
                "auto": true,
                "align": "left"
            },
            "representation": {
                "type": "text",
                "urlLabel": ""
            }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
            "qOtherMode": "OTHER_OFF",
            "qOtherCounted": {
                "qv": "10"
            },
            "qOtherLimit": {
                "qv": "0"
            },
            "qOtherLimitMode": "OTHER_GE_LIMIT",
            "qSuppressOther": false,
            "qForceBadValueKeeping": true,
            "qApplyEvenWhenPossiblyWrongResult": true,
            "qGlobalOtherGrouping": false,
            "qOtherCollapseInnerDimensions": false,
            "qOtherSortMode": "OTHER_SORT_DESCENDING",
            "qTotalMode": "TOTAL_OFF",
            "qReferencedExpression": {
                "qv": ""
            }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
            "qv": "Overige"
        },
        "qTotalLabel": {
            "qv": ""
        },
        "qCalcCond": {
            "qv": ""
        },
    },
    financenumber: {
      "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
              "[$(vD_Order.Debtor.FinancialNumber)]"
          ],
          "qSortCriterias": [
              {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                      "qv": ""
                  },
                  "qSortByGreyness": 0
              }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.Debtor.FinancialNumber)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
              "auto": true,
              "align": "left"
          },
          "representation": {
              "type": "text",
              "urlLabel": ""
          }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
              "qv": "10"
          },
          "qOtherLimit": {
              "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
              "qv": ""
          }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
          "qv": "Overige"
      },
      "qTotalLabel": {
          "qv": ""
      },
      "qCalcCond": {
          "qv": ""
      },
  },
},
  order:{
    servicedescription: {
      "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
              "[$(vD_Order.ServiceDescription)]"
          ],
          "qSortCriterias": [
              {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                      "qv": ""
                  },
                  "qSortByGreyness": 0
              }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.ServiceDescription)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
              "auto": true,
              "align": "left"
          },
          "representation": {
              "type": "text",
              "urlLabel": ""
          }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
              "qv": "10"
          },
          "qOtherLimit": {
              "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
              "qv": ""
          }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
          "qv": "Overige"
      },
      "qTotalLabel": {
          "qv": ""
      },
      "qCalcCond": {
          "qv": ""
      },
  },
    accountmanager: {
    "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
            "[$(vD_^Order.AccountManager)]"
        ],
        "qSortCriterias": [
            {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                    "qv": ""
                },
                "qSortByGreyness": 0
            }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='$(vD_^Order.AccountManager)'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
            "auto": true,
            "align": "left"
        },
        "representation": {
            "type": "text",
            "urlLabel": ""
        }
    },
    "qNullSuppression": false,
    "qIncludeElemValue": false,
    "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
            "qv": "10"
        },
        "qOtherLimit": {
            "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
            "qv": ""
        }
    },
    "qShowTotal": false,
    "qShowAll": false,
    "qOtherLabel": {
        "qv": "Overige"
    },
    "qTotalLabel": {
        "qv": ""
    },
    "qCalcCond": {
        "qv": ""
    },
},
    relatiebeheerder: {
  "qDef": {
      "qGrouping": "N",
      "qFieldDefs": [
          "[$(vD_^Order.RelatieBeheerder)]"
      ],
      "qSortCriterias": [
          {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                  "qv": ""
              },
              "qSortByGreyness": 0
          }
      ],
      "qNumberPresentations": [],
      "qReverseSort": false,
      "qActiveField": 0,
      "qLabelExpression": "='$(vD_^Order.RelatieBeheerder)'",
      "autoSort": true,
      "othersLabel": "Overige",
      "textAlign": {
          "auto": true,
          "align": "left"
      },
      "representation": {
          "type": "text",
          "urlLabel": ""
      }
  },
  "qNullSuppression": false,
  "qIncludeElemValue": false,
  "qOtherTotalSpec": {
      "qOtherMode": "OTHER_OFF",
      "qOtherCounted": {
          "qv": "10"
      },
      "qOtherLimit": {
          "qv": "0"
      },
      "qOtherLimitMode": "OTHER_GE_LIMIT",
      "qSuppressOther": false,
      "qForceBadValueKeeping": true,
      "qApplyEvenWhenPossiblyWrongResult": true,
      "qGlobalOtherGrouping": false,
      "qOtherCollapseInnerDimensions": false,
      "qOtherSortMode": "OTHER_SORT_DESCENDING",
      "qTotalMode": "TOTAL_OFF",
      "qReferencedExpression": {
          "qv": ""
      }
  },
  "qShowTotal": false,
  "qShowAll": false,
  "qOtherLabel": {
      "qv": "Overige"
  },
  "qTotalLabel": {
      "qv": ""
  },
  "qCalcCond": {
      "qv": ""
  },
},
    team: {
  "qDef": {
      "qGrouping": "N",
      "qFieldDefs": [
          "[$(vD_^Order.Team)]"
      ],
      "qSortCriterias": [
          {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                  "qv": ""
              },
              "qSortByGreyness": 0
          }
      ],
      "qNumberPresentations": [],
      "qReverseSort": false,
      "qActiveField": 0,
      "qLabelExpression": "='$(vD_^Order.Team)'",
      "autoSort": true,
      "othersLabel": "Overige",
      "textAlign": {
          "auto": true,
          "align": "left"
      },
      "representation": {
          "type": "text",
          "urlLabel": ""
      }
  },
  "qNullSuppression": false,
  "qIncludeElemValue": false,
  "qOtherTotalSpec": {
      "qOtherMode": "OTHER_OFF",
      "qOtherCounted": {
          "qv": "10"
      },
      "qOtherLimit": {
          "qv": "0"
      },
      "qOtherLimitMode": "OTHER_GE_LIMIT",
      "qSuppressOther": false,
      "qForceBadValueKeeping": true,
      "qApplyEvenWhenPossiblyWrongResult": true,
      "qGlobalOtherGrouping": false,
      "qOtherCollapseInnerDimensions": false,
      "qOtherSortMode": "OTHER_SORT_DESCENDING",
      "qTotalMode": "TOTAL_OFF",
      "qReferencedExpression": {
          "qv": ""
      }
  },
  "qShowTotal": false,
  "qShowAll": false,
  "qOtherLabel": {
      "qv": "Overige"
  },
  "qTotalLabel": {
      "qv": ""
  },
  "qCalcCond": {
      "qv": ""
  },
}, 
    afzender: {
  "qDef": {
      "qGrouping": "N",
      "qFieldDefs": [
          "[$(vD_Order.Afzender)]"
      ],
      "qSortCriterias": [
          {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                  "qv": ""
              },
              "qSortByGreyness": 0
          }
      ],
      "qNumberPresentations": [],
      "qReverseSort": false,
      "qActiveField": 0,
      "qLabelExpression": "='$(vD_Order.Afzender)'",
      "autoSort": true,
      "othersLabel": "Overige",
      "textAlign": {
          "auto": true,
          "align": "left"
      },
      "representation": {
          "type": "text",
          "urlLabel": ""
      }
  },
  "qNullSuppression": false,
  "qIncludeElemValue": false,
  "qOtherTotalSpec": {
      "qOtherMode": "OTHER_OFF",
      "qOtherCounted": {
          "qv": "10"
      },
      "qOtherLimit": {
          "qv": "0"
      },
      "qOtherLimitMode": "OTHER_GE_LIMIT",
      "qSuppressOther": false,
      "qForceBadValueKeeping": true,
      "qApplyEvenWhenPossiblyWrongResult": true,
      "qGlobalOtherGrouping": false,
      "qOtherCollapseInnerDimensions": false,
      "qOtherSortMode": "OTHER_SORT_DESCENDING",
      "qTotalMode": "TOTAL_OFF",
      "qReferencedExpression": {
          "qv": ""
      }
  },
  "qShowTotal": false,
  "qShowAll": false,
  "qOtherLabel": {
      "qv": "Overige"
  },
  "qTotalLabel": {
      "qv": ""
  },
  "qCalcCond": {
      "qv": ""
  },
},
    division: {
  "qDef": {
      "qGrouping": "N",
      "qFieldDefs": [
          "[$(vD_Order.Division)]"
      ],
      "qSortCriterias": [
          {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                  "qv": ""
              },
              "qSortByGreyness": 0
          }
      ],
      "qNumberPresentations": [],
      "qReverseSort": false,
      "qActiveField": 0,
      "qLabelExpression": "='$(vD_Order.Division)'",
      "autoSort": true,
      "othersLabel": "Overige",
      "textAlign": {
          "auto": true,
          "align": "left"
      },
      "representation": {
          "type": "text",
          "urlLabel": ""
      }
  },
  "qNullSuppression": false,
  "qIncludeElemValue": false,
  "qOtherTotalSpec": {
      "qOtherMode": "OTHER_OFF",
      "qOtherCounted": {
          "qv": "10"
      },
      "qOtherLimit": {
          "qv": "0"
      },
      "qOtherLimitMode": "OTHER_GE_LIMIT",
      "qSuppressOther": false,
      "qForceBadValueKeeping": true,
      "qApplyEvenWhenPossiblyWrongResult": true,
      "qGlobalOtherGrouping": false,
      "qOtherCollapseInnerDimensions": false,
      "qOtherSortMode": "OTHER_SORT_DESCENDING",
      "qTotalMode": "TOTAL_OFF",
      "qReferencedExpression": {
          "qv": ""
      }
  },
  "qShowTotal": false,
  "qShowAll": false,
  "qOtherLabel": {
      "qv": "Overige"
  },
  "qTotalLabel": {
      "qv": ""
  },
  "qCalcCond": {
      "qv": ""
  },
},
    zendinggroottegroep: {
    "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
            "[$(vD_Order.Zendinggrootte.Group)]"
        ],
        "qSortCriterias": [
            {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                    "qv": ""
                },
                "qSortByGreyness": 0
            }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='$(vD_Order.Zendinggrootte.Group)'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
            "auto": true,
            "align": "left"
        },
        "representation": {
            "type": "text",
            "urlLabel": ""
        }
    },
    "qNullSuppression": false,
    "qIncludeElemValue": false,
    "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
            "qv": "10"
        },
        "qOtherLimit": {
            "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
            "qv": ""
        }
    },
    "qShowTotal": false,
    "qShowAll": false,
    "qOtherLabel": {
        "qv": "Overige"
    },
    "qTotalLabel": {
        "qv": ""
    },
    "qCalcCond": {
        "qv": ""
    },
  },
  dossier: {
    "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
            "[$(vD_^JKS.Order.Dossier)]"
        ],
        "qSortCriterias": [
            {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": 1,
                "qSortByAscii": 1,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                    "qv": ""
                },
                "qSortByGreyness": 0
            }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='$(vD_^JKS.Order.Dossier)'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
            "auto": true,
            "align": "left"
        },
        "representation": {
            "type": "text",
            "urlLabel": ""
        }
    },
    "qNullSuppression": false,
    "qIncludeElemValue": false,
    "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
            "qv": "10"
        },
        "qOtherLimit": {
            "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
            "qv": ""
        }
    },
    "qShowTotal": false,
    "qShowAll": false,
    "qOtherLabel": {
        "qv": "Overige"
    },
    "qTotalLabel": {
        "qv": ""
    },
    "qCalcCond": {
        "qv": ""
    },
},
  }
}
const measures = {
    omzet: {
      notransport: {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "OrderOmzetNoTransport",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "$(vE_OrderOmzetNoTransport)",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 2,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "='$(vL_OrderOmzetNoTransport)'",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
    },
  };
export {measures,dimensions};