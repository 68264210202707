import qlik from './qlik';
import {measures} from './qlik/qlik';
import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions } from '../orders/qlik/klant/klant';

export default [
  {
    value: 'dimensions',
    label: 'Dimensions',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            value: 'orderdimensions.order.ordernumberdisplay',
            label: 'Job',
            qlik: orderdimensions.order.ordernumberdisplay
          }, 
          {
            label: 'Debtor',
            value: 'debiteur',
            children: [
              {
              value: "orderdimensions.debtor.name",
              label: "Name",
              qlik: orderdimensions.debtor.name
              },
              {
              value: "orderdimensions.debtor.number",
              label: 'Number',
              qlik: orderdimensions.debtor.number
              },
            ]
          },
          {
            label: 'Loading Location',
            value: 'laadlocatie',
            children: [
              {
                value: 'dimensions.loadinglocation.name',
                label: 'Name',
                qlik: orderdimensions.loadinglocation.name
              },
              {
                value: 'dimensions.loadinglocation.number',
                label: 'ID',
                qlik: orderdimensions.loadinglocation.id
              },
              {
                value: 'dimensions.loadinglocation.zipcode',
                label: 'ZIP code',
                qlik: orderdimensions.loadinglocation.zipcode
              },
              {
                value: 'dimensions.loadinglocation.city',
                label: 'City',
                qlik: orderdimensions.loadinglocation.city
              },
              {
                value: 'dimensions.loadinglocation.country',
                label: 'Country',
                qlik: orderdimensions.loadinglocation.country
              }
            ]
          },
          {
            label: 'Unloading Location',
            value: 'loslocatie',
            children: [
              {
                value: 'dimensions.unloadinglocation.name',
                label: 'Naam',
                qlik: orderdimensions.unloadinglocation.name
              },
              {
                value: 'dimensions.unloadinglocation.number',
                label: 'ID',
                qlik: orderdimensions.unloadinglocation.id
              },
              {
                value: 'dimensions.unloadinglocation.zipcode',
                label: 'ZIP code',
                qlik: orderdimensions.unloadinglocation.zipcode
              },
              {
                value: 'dimensions.unloadinglocation.city',
                label: 'City',
                qlik: orderdimensions.unloadinglocation.city
              },
              {
                value: 'dimensions.unloadinglocation.country',
                label: 'Country',
                qlik: orderdimensions.unloadinglocation.country
              },
            ]
          },
        ]
      },
      {
        label: 'Trip',
        value: 'rit',
        children: [
          {
            value: 'ritdimensions.trip.number',
            label: 'Trip Number',
            qlik: ritdimensions.trip.number
          },
          {
            label: 'Driver',
            value: 'driver',
            children: [
              {
                label: 'Name',
                value: 'ritdimensions.driver.name',
                qlik: ritdimensions.driver.name
              },
              {
                label: 'Number',
                value: 'ritdimensions.driver.number',
                qlik: ritdimensions.driver.number
              }
            ]
          },
          {
            label: 'Vehicle',
            value: 'vehicle',
            children: [
              {
                label: 'Number',
                value: 'ritdimensions.vehicle.number',
                qlik: ritdimensions.vehicle.number
              },
              {
                label: 'License',
                value: 'ritdimensions.vehicle.name',
                qlik: ritdimensions.vehicle.name
              }
            ]
          },
          {
            label: 'Charter',
            value: 'crediteur',
            children: [
              {
                label: 'Name',
                value: 'ritdimensions.charter.name',
                qlik: ritdimensions.charter.name
              },
              {
                label: 'Number',
                value: 'ritdimensions.charter.number',
                qlik: ritdimensions.charter.number
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                label: 'Number',
                value: 'ritdimensions.trailer.number',
                qlik: ritdimensions.trailer.number
              },
            ]
          },

        ]
      },
      {
        label: 'Period',
        value: 'periode',
        children: [
          {
            value: 'ritdimensions.period.year',
            label: 'Year',
            qlik: ritdimensions.period.year
          },
          {
            value: 'ritdimensions.period.quarter',
            label: 'Quarter',
            qlik: ritdimensions.period.quarter
          },
          {
            value: 'ritdimensions.period.month',
            label: 'Month',
            qlik: ritdimensions.period.month
          },
          {
            value: 'ritdimensions.period.period',
            label: 'Period',
            qlik: ritdimensions.period.period
          },
          {
            value: 'ritdimensions.period.week',
            label: 'Week',
            qlik: ritdimensions.period.week
          },
          {
            value: 'ritdimensions.period.date',
            label: 'Date',
            qlik: ritdimensions.period.date
          },
        ]
      }
    ]
  },
  {
    label: 'Measures',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Turnover',
        children: [
          {
            value: 'measures.omzet.totaal',
            label: 'Total',
            qlik: measures.omzet.totaal
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Costs',
        children: [
          {
            value: 'measures.kosten.totaal',
            label: 'Total',
            qlik: measures.kosten.totaal,
            help: 'Totaal van de directe kosten.'
          },
          {
            value: 'measures.kosten.charter',
            label: 'Charter', 
            qlik: measures.kosten.charter,
            help: 'Kosten van kilometer, uren en vaste charters'
          },
          {
            value: 'measures.kosten.toll',
            label: 'Toll',
            qlik: measures.kosten.toll
          },
          {
            value: 'measures.kosten.warehouse',
            label: 'Warehouse',
            qlik: measures.kosten.warehouse
          },
            {
              value: 'measures.kosten.boat',
              label: 'Boot',
              qlik: measures.kosten.boat,
              help: 'Boot kosten vanuit Transpas voor de reservering van de boot kosten per zending'
            },
            {
              value: 'measures.kosten.rail',
              label: 'Trein',
              qlik: measures.kosten.rail,
              help: 'Trein kosten vanuit Transpas voor de reservering van de rail kosten per zending'
            },
          {
            value: 'measures.kosten.other',
            label: 'Other',
            qlik: measures.kosten.other,
            help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Count',
        children: [
          {
            value: 'measures.aantal.dossiers',
            label: 'Dossiers',
            qlik: measures.aantal.dossiers,
            help: 'Het aantal dossiers, waarbij meerdere zendingen gecombineerd worden'
          },
          {
            value: 'measures.aantal.orders',
            label: 'Jobs',
            qlik: measures.aantal.orders,
            help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
          },
          {
            value: 'measures.aantal.ritten',
            label: 'Trips',
            qlik: measures.aantal.ritten,
            help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'measures.kpi.goedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },
          {
            value: 'measures.kpi.normmarginpercent',
            label: 'Norm Margin %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            value: 'measures.kpi.normmarginomzet',
            label: 'Norm Margin',
            qlik: measures.kpi.normmarginomzet
          },
        ]
      },
      {
        value: 'eigenwagen',
        label: 'Own Vehicle',
        children: [
          {
            value: 'measures.own.omzetperuur',
            label: 'TO / Hour',
            qlik: measures.own.omzetperuur
          },
          {
            value: 'measures.own.normomzetperuur',
            label: 'Norm TO / Hour',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Charter',
        children: [
          {
            value: 'measures.outsourced.percentage',
            label: '% Charter',
            qlik: measures.outsourced.percentage
          },
          {
            value: 'measures.outsourced.marge',
            label: 'Margin',
            qlik: measures.outsourced.marge
          },
          {
            value: 'measures.outsourced.norm',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'measures.km.km',
            label: 'Totaal',
            qlik: measures.km.km
          },
          {
            value: 'measures.km.omzetperkm',
            label: 'TO / KM',
            qlik: measures.km.omzetperkm
          },
          {
            value: 'measures.km.normomzetperkm',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm
          },
        ]
      },
      {
        value: 'uren',
        label: 'Hours',
        children: [
          {
            value: 'measures.uren.totaal',
            label: 'Total (Σ)',
            qlik: measures.uren.totaal
          },
          {
            value: 'measures.uren.loading',
            label: 'Loading (Σ)',
            qlik: measures.uren.loading
          },
          {
            value: 'measures.uren.unloading',
            label: 'Unloading (Σ)',
            qlik: measures.uren.unloading
          },
          {
            value: 'measures.uren.driving',
            label: 'Drive (Σ)',
            qlik: measures.uren.driving
          },
          {
            value: 'measures.uren.other',
            label: 'Other (Σ)',
            qlik: measures.uren.other
          },
          {
            value: 'measures.uren.avg_totaal',
            label: 'Total (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            value: 'measures.uren.avg_loading',
            label: 'Loading (avg)',
            qlik: measures.uren.avg_loading
          },
          {
            value: 'measures.uren.avg_unloading',
            label: 'Unloading (avg)',
            qlik: measures.uren.avg_unloading
          },
          {
            value: 'measures.uren.avg_driving',
            label: 'Drive (avg)',
            qlik: measures.uren.avg_driving
          },
          {
            value: 'measures.uren.avg_other',
            label: 'Other (avg)', 
            qlik: measures.uren.avg_other 
          },
        ]
      },
    ]
  }
];
