const measures = {
  kosten: {
    correctiediesel: {
      "qLibraryId": "",
      "qDef": {
        "qLabel": "",
        "qDescription": "",
        "qTags": [],
        "qGrouping": "N",
        "qDef": "$(vE_TripKosten(Kind={'FuelCorrection'}))",
        "qNumFormat": {
          "qType": "U",
          "qnDec": 10,
          "qUseThou": 0,
          "qFmt": "",
          "qDec": "",
          "qThou": ""
        },
        "qRelative": false,
        "qBrutalSum": false,
        "qAggrFunc": "Expr",
        "qAccumulate": 0,
        "qReverseSort": false,
        "qActiveExpression": 0,
        "qExpressions": [],
        "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & 'Correctie dieselprijs'",
        "autoSort": true,
        "cId": "eUNc",
        "numFormatFromTemplate": true,
        "textAlign": {
          "auto": true,
          "align": "left"
        }
      },
      "qSortBy": {
        "qSortByState": 0,
        "qSortByFrequency": 0,
        "qSortByNumeric": -1,
        "qSortByAscii": 0,
        "qSortByLoadOrder": 1,
        "qSortByExpression": 0,
        "qExpression": {
          "qv": ""
        },
        "qSortByGreyness": 0
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCond": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    }
  }
};

export {measures};