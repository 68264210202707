import qlik from './qlik';
import {dimensions, measures} from './qlik/klant/klant';
import {dimensions as fri_dimensions, measures as fri_measures} from './qlik/klant/fri';

export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Opdrachtgever',
          value: 'klant',
          children: [
            {
              new_value: "dimensions.customer.name",
              value: 'vD_Order.Customer.Name',

              label: 'Naam',
              qlik: dimensions.customer.name,
            },
            {
              new_value: "dimensions.customer.number",
              value: 'vD_Order.Customer.Number',
              label: 'Nummer',
              qlik: dimensions.customer.number
            },
            {
              new_value: "dimensions.customer.type",
              value: 'vD_Order.Customer.Type',
              label: 'Type',
              qlik: dimensions.customer.type
            }
          ]
        },
        {
          label: 'Debiteur',
          value: 'debiteur',
          children: [
            {
              new_value: "dimensions.debtor.name",
              value: 'vD_Order.Debtor.Name',
              label: 'Naam',
              qlik: dimensions.debtor.name,
            },
            {
              new_value: "dimensions.debtor.number",
              value: 'vD_Order.Debtor.Number',
              label: 'Nummer',
              qlik: dimensions.debtor.number
            }
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              new_value: "dimensions.order.number",
              value: 'vD_Order.OrderNumber',
              qlik: dimensions.order.number,
              label: 'Technisch ordernummer'
            },
            {
              new_value: "dimensions.order.dossier",
              value: 'vD_Order.Dossier',
              qlik: dimensions.order.dossier,
              label: 'Dossier'
            },
            {
              new_value: "dimensions.order.ordernumberdisplay",
              value: 'vD_Order.OrderNumberDisplay',
              qlik: dimensions.order.ordernumberdisplay,
              label: 'Zending'
            },
            {
              new_value: "dimensions.order.reference",
              value: 'vD_Order.Reference',
              qlik: dimensions.order.reference,
              label: 'Referentie'
            },
            {
              new_value: "dimensions.order.plangroup",
              value: 'vD_Order.PlanGroup',
              qlik: dimensions.order.plangroup,
              label: 'Plangroep'
            },
            {
              new_value: "dimensions.order.status",
              value: 'vD_Order.Status',
              qlik: dimensions.order.status,
              label: 'Status'
            },
            {
              new_value: "dimensions.order.service",
              value: 'vD_Order.Service',
              qlik: dimensions.order.service,
              label: 'Service'
            },
            {
              new_value: "fri_dimensions.order.type",
              value: 'vD_Order.Type',
              qlik: fri_dimensions.order.type ,
              label: 'Type'
            }
          ]
        },
        {
          label: 'Laad Locatie',
          value: 'laadlocatie',
          children: [
            {
              new_value: "dimensions.loadinglocation.name",
              value: 'vD_Order.LoadingLocation.Name',
              qlik: dimensions.loadinglocation.name,
              label: 'Naam'
            },
            {
              new_value: "dimensions.loadinglocation.adress",
              value: 'vD_Order.LoadingLocation.Adress',
              qlik: dimensions.loadinglocation.adress,
              label: 'Adres'
            },
            {
              new_value: "dimensions.loadinglocation.searchcode",
              value: 'vD_Order.LoadingLocation.SearchCode',
              qlik: dimensions.loadinglocation.searchcode,
              label: 'Zoekcode'
            },
            {
              new_value: "dimensions.loadinglocation.zipcode",
              value: 'vD_Order.LoadingLocation.ZIPcode',
              qlik: dimensions.loadinglocation.zipcode,
              label: 'Postcode'
            },
            {
              new_value: "dimensions.loadinglocation.city",
              value: 'vD_Order.LoadingLocation.City',
              qlik: dimensions.loadinglocation.city,
              label: 'Plaats'
            },
            {
              new_value: "dimensions.loadinglocation.district",
              value: 'vD_Order.LoadingLocation.District',
              qlik: dimensions.loadinglocation.district,
              label: 'Gemeente'
            },
            {
              new_value: "dimensions.loadinglocation.province",
              qlik: dimensions.loadinglocation.province,
              value: 'vD_Order.LoadingLocation.Province',
              label: 'Provincie'
            },
            {
              new_value: "dimensions.loadinglocation.country",
              value: 'vD_Order.LoadingLocation.Country',
              qlik: dimensions.loadinglocation.country,
              label: 'Land'
            },
            {
              new_value: "dimensions.loadinglocation.land",
              value: 'vD_Order.LoadingLocation.Land',
              qlik: dimensions.loadinglocation.land,
              label: 'Land (ISO)'
            },
          ]
        },
        {
          label: 'Los Locatie',
          value: 'loslocatie',
          children: [
            {
              new_value: "dimensions.unloadinglocation.name",
              value: 'vD_Order.UnloadingLocation.Name',
              qlik: dimensions.unloadinglocation.name,
              label: 'Naam'
            },
            {
              new_value: "dimensions.unloadinglocation.adress",
              value: 'vD_Order.UnloadingLocation.Adress',
              qlik: dimensions.unloadinglocation.adress,
              label: 'Adres'
            },
            {
              new_value: "dimensions.unloadinglocation.searchcode",
              value: 'vD_Order.UnloadingLocation.SearchCode',
              qlik: dimensions.unloadinglocation.searchcode,
              label: 'Zoekcode'
            },
            {
              new_value: "dimensions.unloadinglocation.zipcode",
              value: 'vD_Order.UnloadingLocation.ZIPcode',
              qlik: dimensions.unloadinglocation.zipcode,
              label: 'Postcode'
            },
            {
              new_value: "dimensions.unloadinglocation.city",
              value: 'vD_Order.UnloadingLocation.City',
              qlik: dimensions.unloadinglocation.city,
              label: 'Plaats'
            },
            {
              new_value: "dimensions.unloadinglocation.district",
              value: 'vD_Order.UnloadingLocation.District',
              qlik: dimensions.unloadinglocation.district,
              label: 'Gemeente'
            },
            {
              new_value: "dimensions.unloadinglocation.province",              
              value: 'vD_Order.UnloadingLocation.Province',
              qlik: dimensions.unloadinglocation.province,
              label: 'Provincie'
            },
            {
              new_value: "dimensions.unloadinglocation.country",
              value: 'vD_Order.UnloadingLocation.Country',
              qlik: dimensions.unloadinglocation.country,
              label: 'Land'
            },
            {
              new_value: "dimensions.unloadinglocation.land",
              value: 'vD_Order.UnloadingLocation.Land',
              qlik: dimensions.unloadinglocation.land,
              label: 'Land (ISO)'
            },
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              new_value: "dimensions.period.year",
              value: 'vD_Year',
              qlik: dimensions.period.year,
              label: 'Jaar'
            },
            {
              new_value: "dimensions.period.quarter",
              value: 'vD_Quarter',
              qlik: dimensions.period.quarter,
              label: 'Kwartaal'
            },
            {
              new_value: "dimensions.period.month",
              value: 'vD_Month',
              qlik: dimensions.period.month,
              label: 'Maand'
            },
            {
              new_value: "dimensions.period.week",
              value: 'vD_Week',
              qlik: dimensions.period.week,
              label: 'Week'
            },
            {
              new_value: "dimensions.period.date",
              value: 'vD_Date',
              qlik: dimensions.period.date,
              label: 'Datum'
            },
            {
              new_value: "dimensions.period.weekday",
              value: 'vD_Weekday',
              qlik: dimensions.period.weekday,
              label: 'Dag'
            },
          
          ]
        },
        {
          label: 'Afwijkingen',
          value: 'afwijkingen',
          children: [
            {
              new_value: 'fri_dimensions.afwijking.code',
              value: 'vD_AfwijkingsoortCode',
              qlik: fri_dimensions.afwijking.code,
              label: 'Code'
            },
            {
              new_value: 'fri_dimensions.afwijking.soort',
              value: 'vD_Afwijkingsoort',
              qlik: fri_dimensions.afwijking.soort,
              label: 'Soort'
            },
            {
              new_value: 'fri_dimensions.afwijking.onderwerp',
              value: 'vD_AfwijkingOnderwerp',
              qlik: fri_dimensions.afwijking.onderwerp,
              label: 'Onderwerp'
            },
            {
              new_value: 'fri_dimensions.afwijking.status',
              value: 'vD_AfwijkingStatus',
              qlik: fri_dimensions.afwijking.status,
              label: 'Status'
            }
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              new_value: "measures.omzet.totaal",
              value: 'vE_OrderOmzet',
              qlik: measures.omzet.totaal,
              label: 'Totaal'
            },
            {
              new_value: "measures.omzet.freight",
              value: 'vE_OrderOmzet.Vracht',
              qlik: measures.omzet.freight,
              label: 'Vracht',
              help: 'Vracht omzet vanuit Transpas'
            },
            {
              new_value: "measures.omzet.fuel",
              value: 'vE_OrderOmzet.Fuel',
              qlik: measures.omzet.fuel,
              label: 'Brandstof',
              help: 'Brandstoftoeslagen zoals deze berekend worden op zendingniveau in Transpas'
            },
            {
              new_value: "measures.omzet.extra",
              value: 'vE_OrderOmzet.ExtraKosten',
              qlik: measures.omzet.extra,
              label: 'Extra kosten',
              help: 'Opbrengsten beschikbaar in Transpas onder het menuitem \'Extra Kosten\''
            },
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              new_value: "measures.kosten.totaal",
              value: 'vE_OrderKosten',
              qlik: measures.kosten.totaal,
              label: 'Totaal',
              help: 'Totaal van de directe kosten.'
            },
            {
              new_value: "measures.kosten.charter",
              value: 'vE_OrderKostenCharter',
              qlik: measures.kosten.charter,
              label: 'Vracht',
              help: 'Kosten van kilometer, uren en vaste charters.'
            },
            {
              new_value: "measures.kosten.toll",
              value: 'vE_OrderKostenTol',
              qlik: measures.kosten.toll,
              label: 'Tol',
              help: 'Tolkosten worden enkel vanuit Transpas meegenomen wanneer ze geregistreerd worden op een eigen auto'
            },
            {
              new_value: "measures.kosten.collect",
              value: 'vE_OrderKostenCollect',
              qlik: measures.kosten.collect,
              label: 'Voorhaal',
              help: 'Voorcalculatorische kosten voor de reservering van de directe voorhaalkosten per zending.'
            },
            {
              new_value: "measures.kosten.warehouse",
              value: 'vE_OrderKostenWarehouse',
              qlik: measures.kosten.warehouse,
              label: 'Crossdock',
              help: 'Voorcalculatorische kosten berekend door de MargeMonitor voor de reservering van de directe crossdock kosten per zending'
            },
            {
              new_value: "measures.kosten.other",
              value: 'vE_OrderKostenOther',
              qlik: measures.kosten.other,
              label: 'Overig',
              help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              new_value: "measures.aantal.dossiers",
              value: 'vE_OrderAantalDossiers',
              qlik: measures.aantal.dossiers,
              label: 'Dossiers',
              help: 'Het aantal dossiers, waarbij meerdere zendingen gecombineerd worden'
            },
            {
              new_value: "measures.aantal.orders",
              value: 'vE_OrderAantalOrders',
              qlik: measures.aantal.orders,
              label: 'Zendingen',
              help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
            },
            {
              new_value: "measures.aantal.ritten",
              value: 'vE_OrderAantalRitten',
              qlik: measures.aantal.ritten,
              label: 'Ritten',
              help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
            },
            {
              new_value: "measures.aantal.afwijkingen",
              value: 'vE_OrderAantalAfwijkingen',
              qlik: measures.aantal.afwijkingen,
              label: 'Afwijkingen',
              help: 'Het aantal afwijkingen geregistreerd de geselecteerde zendingen. Hier zien we altijd het totaal. '
            }
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              new_value: "measures.kpi.goedkeur",
              value: 'vE_KPI_OrderGoedkeur',
              qlik: measures.kpi.goedkeur,
              label: 'OK',
              help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kengetallen.'
            },
  
            {
              new_value: "measures.kpi.normmarginpercent",
              value: 'vE_KPI_OrderNormMarginPercent',
              qlik: measures.kpi.normmarginpercent,
              label: 'Norm Marge %',
              help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
            },
            {
              new_value: "measures.kpi.normmarginomzet",
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              qlik: measures.kpi.normmarginomzet,
              label: 'Norm Marge',
              help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
            },
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              new_value: "measures.own.omzetperuur",
              value: 'vE_KPI_OrderOmzetPerUur',
              qlik: measures.own.omzetperuur,
              label: 'Omzet / Uur', 
              help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            },
            {
              new_value: "measures.own.tripomzetperuur",
              value: 'vE_KPI_Order_TripOmzetPerUur',
              qlik: measures.own.tripomzetperuur,
              label: 'Omzet / Uur - rit',
              help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
            },
            {
              new_value: "measures.own.normomzetperuur",
              value: 'vE_OrderNormTurnoverHour',
              qlik: measures.own.normomzetperuur,
              label: 'Norm Omzet / Uur',
              help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '

            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              new_value: 'measures.outsourced.percentage',
              value: 'vE_KPI_OrderTripOutsourced',
              qlik: measures.outsourced.percentage,
              label: '% Uitbesteed',
              help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
            },
            {
              new_value: 'measures.outsourced.marge',
              value: 'vE_KPI_OrderCharterMarge',
              qlik: measures.outsourced.marge,
              label: 'Derden Marge',
              help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. '
            },
            {
              new_value: 'measures.outsourced.norm',
              value: 'vE_OrderNormCharter',
              qlik: measures.outsourced.norm,
              label: 'Norm',
              help: 'De geconfigureerde norm op orderniveau voor het uitbesteede werk. De Derden Marge wordt getoetst aan deze norm. '
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              new_value: 'measures.potentie.totaal',
              value: 'vE_OrderPotency',
              qlik: measures.potentie.totaal,
              label: 'Totaal',
              help:'Deze meetwaarde geeft een indicatie van hoeveel extra omzet er bij komt als alle orders minstens volgens de gestelde norm omzet/uur gaan presteren. Er zijn uren gebruikt voor orders die een ondermaatse omzet/uur halen. De omzet die we missen om deze orders op de norm te laten presteren drukken we uit in de potentie. Potentie wordt alleen uitgerekend voor orders onder de norm.'
            },
            {
              new_value: 'measures.potentie.commercieel',
              value: 'vE_OrderPotencyCommercial',
              qlik: measures.potentie.commercieel,
              label: 'Commercieel',
              help:'Deze meetwaarde geeft aan hoeveel van de totale potentie gewonnen kan worden door de uitvoering van de orders te verbeteren. De toegekende tijden worden beoordeeld volgens de gestelde normen voor het laden/lossen/rijden/overige tijden. Als hier een bedrag staat betekent dat er teveel tijd aan een order gespendeerd is (volgens de gestelde normen).'
            },
            {
              new_value: 'measures.potentie.operationeel',
              value: 'vE_OrderPotencyOperational',
              qlik: measures.potentie.operationeel,
              label: 'Operationeel',
              help:'Deze meetwaarde geeft aan hoeveel van de totale potentie gewonnen kan worden door betere tarieven/toeslagen. De norm tijden voor laden/lossen/rijden/overige tijden worden vermenigvuldigd met de norm omzet/uur om te bepalen wat de omzet zou moeten zijn voor de uitvoer van de order. Dit bedrag minus de omzet (excl directe ritkosten) is de commerciële potentie. Als hier een bedrag staat betekend dat het tarief te laag is voor de prestatie die je van te voren had verwacht.'
            }
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              new_value: 'measures.km.km',
              value: 'vE_OrderKM',
              qlik: measures.km.km,
              label: 'Totaal',
              help: 'Geeft de totale kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              new_value: 'measures.km.omzetperkm',
              value: 'vE_KPI_OrderOmzetPerKM',
              qlik: measures.km.omzetperkm,
              label: 'Omzet / KM',
              help: 'Geeft de omzet per kilometer weer. Hierbij wordt enkel de goedgekeurde omzet afgezet tegen de goedgekeurde kilometers'
            },
            {
              new_value: 'measures.km.normomzetperkm',
              value: 'vE_OrderNormTurnoverKM',
              qlik: measures.km.normomzetperkm,
              label: 'Norm Omzet / KM',
              help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.'
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              new_value: 'measures.uren.totaal',
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
              qlik: measures.uren.totaal,
              help: 'Het totaal aantal uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              new_value: 'measures.uren.loading',
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
              qlik: measures.uren.loading,
              help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              new_value: 'measures.uren.unloading',
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
              qlik: measures.uren.unloading, 
              help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              new_value: 'measures.uren.driving',
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
              qlik: measures.uren.driving,
              help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              new_value: 'measures.uren.other',
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
              qlik: measures.uren.other,
              help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              new_value: 'measures.uren.avg_totaal',
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
              qlik: measures.uren.avg_totaal,
              help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              new_value: 'measures.uren.avg_loading',
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
              qlik: measures.uren.avg_loading,
              help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              new_value: 'measures.uren.avg_unloading',
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
              qlik: measures.uren.avg_unloading,
              help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              new_value: 'measures.uren.avg_driving',
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
              qlik: measures.uren.avg_driving,
              help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              new_value: 'measures.uren.avg_other',
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)',
              qlik: measures.uren.avg_other,
              help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            }
          ]
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'lm',
              label: 'LM',
              children: [
                {
                  new_value: 'measures.persize.lm',
                  value: 'vE_OrderLM',
                  qlik: measures.persize.lm,
                  label: 'Totaal',
                  help: 'Het totaal aantal laadmeters vanuit Transpas'
                },
                {
                  new_value: 'measures.persize.avg_lm',
                  value: 'vE_OrderLMAvg',
                  label: 'Gemiddelde',
                  qlik: measures.persize.avg_lm,
                  help: 'Het gemiddelde aantal laadmeters per zending vanuit Transpas',
                },
                {
                  new_value: 'measures.persize.omzet_shipmentsize',
                  value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
                  qlik: measures.persize.omzet_shipmentsize,
                  label: 'Omzet / LM',
                  help: 'De totale omzet / gedeeld door het totale aantal LM.'
                },
                {
                  new_value: 'measures.persize.loading_shipmentsize',
                  value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
                  qlik: measures.persize.loading_shipmentsize,
                  label: 'Laden / LM',
                  help: 'Het aantal laadminuten per laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  new_value: 'measures.persize.unloading_shipmentsize',
                  value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
                  qlik: measures.persize.unloading_shipmentsize,
                  label: 'Lossen / LM',
                  help: 'Het aantal losminuten per laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  new_value: 'measures.persize.time_shipmentsize',
                  value: 'vE_KPI_OrderMinutenPerZendingGrootte',
                  qlik: measures.persize.time_shipmentsize,
                  label: 'Tijd / LM',
                  help: 'Het aantal minuten per laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
              ]
            },
            {
              value: 'kg',
              label: 'KG',
              children: [
                {
                  new_value: 'measures.persize.kg',
                  value: 'vE_OrderKG',
                  qlik: measures.persize.kg,
                  label: 'Totaal',
                  help: 'Het totaal aantal kilo\'s vanuit Transpas'

                },
                {
                  new_value: 'measures.persize.avg_kg',
                  value: 'vE_OrderKGAvg',
                  qlik: measures.persize.avg_kg,
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal kilo\'s vanuit Transpas'

                }
              ]
            },
            
          ]
        },
      ]
    }
  ];
  