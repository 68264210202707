const dimensions = {
    order: {
      type: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.Type)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.Type)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      zendinggrootte: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Order.Zendinggrootte)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Order.Zendinggrootte)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
    },
    afwijking: {
      code: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_AfwijkingsoortCode)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_AfwijkingsoortCode)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }

      },
      soort: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_Afwijkingsoort)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_Afwijkingsoort)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }

      },
      onderwerp: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_AfwijkingOnderwerp)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_AfwijkingOnderwerp)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }

      },
      status: {
        "qLibraryId": "",
        "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
            "[$(vD_AfwijkingStatus)]"
          ],
          "qFieldLabels": [
            ""
          ],
          "qSortCriterias": [
            {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": 1,
              "qSortByAscii": 1,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_AfwijkingStatus)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
            "auto": true,
            "align": "left"
          },
          "representation": {
            "type": "text",
            "urlLabel": ""
          }
        },
        "qNullSuppression": false,
        "qIncludeElemValue": false,
        "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
            "qv": "10"
          },
          "qOtherLimit": {
            "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
            "qv": ""
          }
        },
        "qShowTotal": false,
        "qShowAll": false,
        "qOtherLabel": {
          "qv": "Overige"
        },
        "qTotalLabel": {
          "qv": ""
        },
        "qCalcCond": {
          "qv": ""
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }

      }
    }
};
const measures = {
  kosten: {
    correctiediesel: {
      "qLibraryId": "",
      "qDef": {
        "qLabel": "",
        "qDescription": "",
        "qTags": [],
        "qGrouping": "N",
        "qDef": "$(vE_OrderKosten(Kind={'FuelCorrection'}))",
        "qNumFormat": {
          "qType": "U",
          "qnDec": 10,
          "qUseThou": 0,
          "qFmt": "",
          "qDec": "",
          "qThou": ""
        },
        "qRelative": false,
        "qBrutalSum": false,
        "qAggrFunc": "Expr",
        "qAccumulate": 0,
        "qReverseSort": false,
        "qActiveExpression": 0,
        "qExpressions": [],
        "qLabelExpression": "='$(vL_OrderKosten)' & ' > ' & 'Correctie dieselprijs'",
        "autoSort": true,
        "cId": "eUNc",
        "numFormatFromTemplate": true,
        "textAlign": {
          "auto": true,
          "align": "left"
        }
      },
      "qSortBy": {
        "qSortByState": 0,
        "qSortByFrequency": 0,
        "qSortByNumeric": -1,
        "qSortByAscii": 0,
        "qSortByLoadOrder": 1,
        "qSortByExpression": 0,
        "qExpression": {
          "qv": ""
        },
        "qSortByGreyness": 0
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCond": {
        "qv": ""
      },
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    }
  }
};

export {measures, dimensions};