import React from 'react';

import orderList from '../../margemonitor/components/orders/dimensions-LUB';
import qlik from '../../margemonitor/components/orders/qlik';
import ritList from '../../margemonitor/components/ritten/dimensions-LUB';
import orderTripList from '../../margemonitor/components/trajecten/dimensions-LUB';


const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  configCloud: {
    host: 'transportcostconsult.eu.qlikcloud.com',
    prefix: '',
    secure: true, 
    webIntegrationId: 'UC7aldzKKXMMGqblEErpfq2xZ-LgCxNv',  
    appId: "08bb1434-4305-4103-b1d0-5562b71bc01b"
  },
  config: {
    host: window.location.hostname,
    secure: true,
    //port: '443',
    prefix: '',
    appId: "8c60a2ab-1de1-45fe-bba6-2060ecd2ec91",
  },
  connections: {
    qlikCloud: false, 
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Year)'],
            placeholder: 'Year',
            expanded: true, 
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Quarter)'],
            placeholder: 'Quarter',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Period)'],
            placeholder: 'Period',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_YearWeek)'],
            placeholder: 'Year-Week',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Week)'],
            placeholder: 'Week',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },   
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Date)'],
            placeholder: 'Date',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Job - LoadingLocation'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Name)]'],
            placeholder: 'Name',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'ZIP code',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Country',
          },
        },
        {
          type: 'title',
          title: 'Job - UnloadingLocation'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Name)]'],
            placeholder: 'Name',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'ZIP code',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Country',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CompanyName)]'],
            placeholder: 'Company Name',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CompanyNumber)]'],
            placeholder: 'Company Number',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Job',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        { 
          type: 'title',
          title: 'Debtor'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Name)]'],
            placeholder: 'Name',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'Number',
            showStateInDropdown: true,
          },
        },
        {
          type: 'title',
          title: 'Characteristics'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Kind)]'],
            placeholder: 'Kind',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.ServiceDescription)]'],
            placeholder: 'Service',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'CIP'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.VerticalName)]'],
            placeholder: 'Vertical Name',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.Region)]'],
            placeholder: 'Region',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.GroupAccountName)]'],
            placeholder: 'Group Account Name',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.AccountManager)]'],
            placeholder: 'Account Manager',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.AccountManagerName)]'],
            placeholder: 'Account Manager Name',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.AccountManagerCountry)]'],
            placeholder: 'Account Manager Country',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.GroupVerticalName)]'],
            placeholder: 'Group Vertical Name',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.GroupAccountManager)]'],
            placeholder: 'Group Account Manager',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.KeyAccountGroupName)]'],
            placeholder: 'Key Account Group Name',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.KeyAccount)]'],
            placeholder: 'Key Account',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.Currency)]'],
            placeholder: 'Currency',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        
      ],
    },
    trip: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Tripnumber',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Vehicle" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Number',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Name',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Vehicle.Type)]'],
            placeholder: 'Type',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Driver" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Number',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Name',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: []
  }, 
  routes: [
  ],
  fieldSettings:{
    period: 'vD_Period'
  },
  selectionBar: 'Capability',
  rollingPeriod: false,
  language: 'en',
  defaultSelections: [
    {
      field: 'Year',
      expression: '=year(today())'
    }
  ]

};

export default options;
