import {dimensions, measures} from './qlik/klant/klant';
import {dimensions as wtr_dimensions, measures as wtr_measures} from './qlik/klant/wtr';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Opdrachtgever',
        value: 'customer',
        children: [
          {
            label: 'Naam',
            value: 'vD_Order.Customer.Name',
            qlik: dimensions.customer.name
          },
          {
            label: 'Nummer',
            value: 'vD_Order.Customer.Number',
            qlik: dimensions.customer.number
          },
        ]
      },
      {
        label: 'Debiteur',
        value: 'debtor',
        children: [
          {
            label: 'Naam',
            value: 'vD_Order.Debtor.Name',
            qlik: dimensions.debtor.name,
          },
          {
            label: 'Nummer',
            value: 'vD_Order.Debtor.Number',
            qlik: dimensions.debtor.number
          }
        ]
      },
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            value: 'vD_Order.Kind',
            label: 'Soort',
            qlik: dimensions.order.kind
          },
          {
            value: 'vD_Order.OrderNumber',
            label: 'Technisch %Order%',
            qlik: dimensions.order.number
          },
          {
            value: 'vD_Order.Dossier',
            label: 'Dossier',
            qlik: dimensions.order.dossier
          },
          {
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Zending',
            qlik: dimensions.order.ordernumberdisplay
          },
          {
            value: 'vD_Order.Company.Name',
            label: 'Bedrijf',
            qlik: dimensions.order.companyname
          },
          {
            value: 'vD_Order.Company.Code',
            label: 'Bedrijfscode',
            qlik:dimensions.order.companycode
          },

          {
            value: 'vD_Order.Reference',
            label: 'Reference',
            qlik: dimensions.order.reference
          },
          {
            value: 'vD_Order.PlanGroup',
            label: 'Plangroep',
            qlik: dimensions.order.plangroup
          },
          {
            value: 'vD_Order.LandGroup',
            label: 'Planafdeling',
            qlik: dimensions.order.plandivision
          },
          {
            value: 'vD_Order.Status',
            label: 'Status',
            qlik: dimensions.order.status
          },
          {
            value: 'vD_Order.CostCenter.Description',
            label: 'Kostenplaats',
            qlik: wtr_dimensions.order.costcenterdescription
          },
        ]
      },
      {
        label: 'Laad Locatie',
        value: 'laadlocatie',
        children: [
          {
            value: 'vD_Order.LoadingLocation.Name',
            label: 'Naam',
            qlik: dimensions.loadinglocation.name
          },
          {
            value: 'vD_Order.LoadingLocation.Adress',
            label: 'Adres',
            qlik: dimensions.loadinglocation.adress
          },
          {
            value: 'vD_Order.LoadingLocation.SearchCode',
            label: 'Zoekcode',
            qlik: dimensions.loadinglocation.searchcode
          },
          {
            value: 'vD_Order.LoadingLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.loadinglocation.zipcode
          },
          {
            value: 'vD_Order.LoadingLocation.City',
            label: 'Plaats',
            qlik: dimensions.loadinglocation.city
          },
          {
            value: 'vD_Order.LoadingLocation.District',
            label: 'Gemeente',
            qlik: dimensions.loadinglocation.district
          },
          {
            value: 'vD_Order.LoadingLocation.Province',
            label: 'Provincie',
            qlik: dimensions.loadinglocation.province
          },
          {
            value: 'vD_Order.LoadingLocation.Country',
            label: 'Land',
            qlik: dimensions.loadinglocation.country
          },
          {
            value: 'vD_Order.LoadingLocation.Land',
            label: 'Land (ISO)',
            qlik: dimensions.loadinglocation.land
          },
        ]
      },
      {
        label: 'Los Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'vD_Order.UnloadingLocation.Name',
            label: 'Naam',
            qlik: dimensions.unloadinglocation.name
          },
          {
            value: 'vD_Order.UnloadingLocation.Adress',
            label: 'Adres',
            qlik: dimensions.unloadinglocation.adress
          },
          {
            value: 'vD_Order.UnloadingLocation.SearchCode',
            label: 'Zoekcode',
            qlik: dimensions.unloadinglocation.searchcode
          },
          {
            value: 'vD_Order.UnloadingLocation.ZIPcode',
            label: 'Postcode',
            qlik: dimensions.unloadinglocation.zipcode
          },
          {
            value: 'vD_Order.UnloadingLocation.City',
            label: 'Plaats',
            qlik: dimensions.unloadinglocation.city
          },
          {
            value: 'vD_Order.UnloadingLocation.District',
            label: 'Gemeente',
            qlik: dimensions.unloadinglocation.district
          },
          {
            value: 'vD_Order.UnloadingLocation.Province',
            label: 'Provincie',
            qlik: dimensions.unloadinglocation.province
          },
          {
            value: 'vD_Order.UnloadingLocation.Country',
            label: 'Land',
            qlik: dimensions.unloadinglocation.country
          },
          {
            value: 'vD_Order.UnloadingLocation.Land',
            label: 'Land (ISO)',
            qlik: dimensions.unloadinglocation.land
          },
        ]
      },
      {
        label: 'Goederen',
        value: 'goederen',
        children: [
          {
            value: 'vD_Product',
            label: 'Product',
            qlik: wtr_dimensions.product.product
          },
          {
            value: 'vD_ProductDescription',
            label: 'Product Omschrijving',
            qlik: wtr_dimensions.product.productdescription
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar',
            qlik: dimensions.period.year
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal',
            qlik: dimensions.period.quarter
          },
          {
            value: 'vD_Month',
            label: 'Maand',
            qlik: dimensions.period.month
          },
          {
            value: 'vD_Week',
            label: 'Week',
            qlik: dimensions.period.week
          },
          {
            value: 'vD_YearWeek',
            label: 'Jaar-Week',
            qlik: dimensions.period.yearweek
          },
          {
            value: 'vD_Date',
            label: 'Datum',
            qlik: dimensions.period.date
          },
          {
            value: 'vD_Weekday',
            label: 'Weekday',
            qlik: dimensions.period.weekday
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_OrderOmzet',
            label: 'Totaal',
            qlik: measures.omzet.totaal
          },
          {
            value: 'vE_OrderOmzetFreight',
            label: 'Vracht',
            help: 'Vrachtkosten vanuit Transpas',
            qlik: measures.omzet.freight
          },
          {
            value: 'vE_OrderOmzetFuel',
            label: 'Brandstof',
            help: 'Brandstoftoeslagen zoals deze berekend worden op zendingniveau in Transpas',
            qlik: measures.omzet.fuel
          },{
            value: 'vE_OrderOmzetMaut',
            label: 'Tol',
            qlik: measures.omzet.toll
          },{
            value: 'vE_OrderOmzetOther',
            help: 'Opbrengsten beschikbaar in Transpas onder het menuitem \'Extra Kosten\' ',
            label: 'Extra Kosten',
            qlik: measures.omzet.extra,
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_OrderKosten',
            label: 'Totaal',
            qlik: measures.kosten.totaal,
            help: 'Totaal van de directe kosten.'
          },
          {
            value: 'vE_OrderKostenCharter',
            label: 'Derden',
            qlik: measures.kosten.charter,
            help: 'Kosten van kilometer, uren en vaste charters'
          },
          {
            value: 'vE_OrderKostenRailFerry',
            label: 'Trein',
            qlik: measures.kosten.rail,
            help: 'Trein kosten vanuit Transpas voor de reservering van de rail kosten per zending'
          },
          {
            value: 'vE_OrderKostenTol',
            label: 'Tol',
            qlik: measures.kosten.toll,
            help: 'Tolkosten worden enkel vanuit Transpas meegenomen wanneer ze geregistreerd worden op een eigen auto'
          },
          {
            value: 'vE_OrderKostenJIB',
            label: 'JIB',
            qlik: wtr_measures.kosten.jib,

          },
          {
            value: 'vE_OrderKostenOther',
            label: 'Overig',
            qlik: measures.kosten.other,
            help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_OrderAantalDossiers',
            label: 'Dossiers',
            qlik: measures.aantal.dossiers,
            help: 'Het aantal dossiers, waarbij meerdere zendingen gecombineerd worden'
          },
          {
            value: 'vE_OrderAantalOrders',
            label: 'Zendingen',
            qlik: measures.aantal.orders,
            help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
          },
          {
            value: 'vE_OrderAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten,
            help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_OrderGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur,
            help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kerngetallen.'
          },

          {
            value: 'vE_KPI_OrderNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent,
            help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
          },
          {
            value: 'vE_KPI_OrderNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet,
            help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_OrderOmzetPerUur',
            label: 'Omzet / Uur', 
            qlik: measures.own.omzetperuur,
            help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
          },
          {
            value: 'vE_KPI_Order_TripOmzetPerUur',
            label: 'Omzet / Uur - rit',
            qlik: measures.own.tripomzetperuur,
            help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
          },
          {
            value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrder',
            label: 'Omzet / Uur - rondrit',
            qlik: measures.own.omzetperuurvolgende,
            help: 'De omzet / uur rondrit is de combinatie van de geselecteerde order + (volgende order of voorgaande order). Aan de rechterzijde kun je in de opties aangeven of je de volgende order, de voorgaande order of zowel de voorgaande in het kengetal omzet/uur rondrit gebruiken. Standaard staat deze instelling op de volgende order. Met dit kengetal kun je een rondrit analyseren zodat de goede heen orders en de slechtere retour orders in combinatie analyseert.'
          },
          {
            value: 'vE_KPI_OrderBezetting',
            label: 'Bezetting',
            qlik: measures.own.bezetting,
          },
          {
            value: 'vE_OrderNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            qlik: measures.own.normomzetperuur,
            help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed', 
            help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
          },
          {
            value: 'vE_KPI_OrderCharterMarge',
            label: 'Derden Marge',            
            qlik: measures.outsourced.marge,
            help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. '
          },
          {
            value: 'vE_OrderNormCharter',
            label: 'Norm',            
            qlik: measures.outsourced.norm,
            help: 'De geconfigureerde norm op orderniveau voor het uitbestede werk. De Derden Marge wordt getoetst aan deze norm. '
          },
        ]
      },
      {
        value: 'potency',
        label: 'Potentie',
        children: [
          {
            value: 'vE_OrderPotency',
            label: 'Totaal',
            qlik: measures.potentie.totaal,
            help:'Potentie geeft aan hoeveel hoger de omzet zou moeten zijn om volledig volgens de gestelde norm omzet/uur te presteren voor eigen werk. De potentie wordt enkel uitgerekend voor orders die onder de norm presteren. Orders die boven de norm presteren compenseren dit cijfer niet.'
          },
          {
            value: 'vE_OrderPotencyCommercial',
            label: 'Commercieel',
            qlik: measures.potentie.commercieel,
            help:'De potentie is te behalen door 2 zaken te verbeteren. Of hogere tarieven of snellere uitvoering van de orders. Hogere tarieven is de commerciële potentie. Commerciële potentie wordt berekend door te kijken naar de voorcalculatie van de tijd en deze te toetsen aan de norm omzet/uur. Dus (voorcalculatie tijd x norm omzet/uur) - gerealiseerde omzet = Commerciële potentie.'
          },
          {
            value: 'vE_OrderPotencyOperational',
            label: 'Operationeel',
            qlik: measures.potentie.operationeel,
            help:'De potentie is te behalen door 2 zaken te verbeteren. Of hogere tarieven of snellere uitvoering van de orders. Snellere uitvoering is de operationele potentie. Operationele potentie wordt berekend door te kijken naar de gerealiseerde tijd en deze te toetsen aan de voorcalculatie van de tijd en het verschil hiervan te vermenigvuldigen met de norm omzet/uur. Dus (gerealiseerde tijd - voorcalculatie tijd) x norm omzet/uur = Operationele potentie.'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_OrderKM',
            label: 'Totaal',
            qlik: measures.km.km,
            help: 'Geeft de totale kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderKMFull',
            label: 'Beladen',
            qlik: measures.km.kmfull,
            help: 'Geeft de beladen kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderKMEmpty',
            label: 'Onbeladen',
            qlik: measures.km.kmempty,
            help: 'Geeft de onbeladen kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_KPI_OrderOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm,
          },
          {
            value: 'vE_KPI_OrderOmzetPerKMFull',
            label: 'Omzet / beladen KM',
            qlik: measures.km.omzetperkmfull,
          },
          {
            value: 'vE_KPI_OrderBelading',
            label: '% Beladen',
            qlik: measures.km.belading
          },
          {
            value: 'vE_OrderNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm,
            help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.'
          },
          {
            value: 'vE_OrderNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            qlik: measures.km.normomzetperkmfull,
            help: 'De geconfigureerde norm op orderniveau voor de Omzet / beladen KM.'
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_OrderUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal,
            help: 'Het totaal aantal uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading,
            help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading,
            help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving,
            help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other,
            help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal,
            help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading,
            help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading,
            help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving,
            help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderUrenAvgOther',
            label: 'Overig (avg)',
            qlik: measures.uren.avg_other,
            help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          }
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Voorgaand jaar',
        children: [
          {
            value: 'vE_OrderOmzetVorigJaar',
            label: 'Omzet',
            qlik: measures.previousyear.omzet,
          },
          {
            value: 'vE_OrderAantalOrdersVorigJaar',
            label: 'Order',
            qlik: measures.previousyear.orders,
          },
          {
            value: 'vE_OrderAantalRittenVorigJaar',
            label: 'Ritten',
            qlik: measures.previousyear.ritten,
          },
          {
            value: 'vE_KPI_OrderGoedkeurVorigJaar',
            label: 'OK',
            qlik: measures.previousyear.goedkeur,
          },

          {
            value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
            label: 'Norm Marge %',
            qlik: measures.previousyear.normmarge,
          },
          {
            value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
            label: 'Norm Marge',
            qlik: measures.previousyear.normmargeomzet,
          },
          {
            value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
            label: 'Omzet / Uur',
            qlik: measures.previousyear.omzetperuur,
          },
          {
            value: 'vE_KPI_OrderOmzetPerUurCombinatieVolgendeOrderVorigJaar',
            label: 'Omzet / Uur - rondrit',
            qlik: measures.previousyear.volgendeorder,
          },
          {
            value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
            label: '% Uitbesteed',
            qlik: measures.previousyear.uitbesteed,
          },
          {
            value: 'vE_KPI_OrderCharterMargeVorigJaar',
            label: 'Derden Marge',
            qlik: measures.previousyear.chargermarge,
          },

        ],
      },
      {
        value: 'persize',
        label: 'Per Eenheid',
        children: [
          {
            value: 'lm',
            label: 'LM',
            children: [
              {
                value: 'vE_OrderLM',
                label: 'Totaal',
                qlik: measures.persize.shipmentsize,
                help: 'Het totaal aantal laadmeters vanuit Transpas'
              },
              {
                value: 'vE_OrderLMAvg',
                label: 'Gemiddelde',
                qlik: measures.persize.avg_lm,
                help: 'Het gemiddelde aantal laadmeters per zending vanuit Transpas',
              },
            ]
          },
          {
            value: 'kg',
            label: 'KG',
            children: [
              {
                value: 'vE_OrderKG',
                label: 'Totaal',
                qlik: measures.persize.kg,
                help: 'Het totaal aantal kilo\'s vanuit Transpas'

              },
              {
                value: 'vE_OrderKGAvg',
                label: 'Gemiddelde',
                qlik: measures.persize.avg_kg, 
                help: 'Het gemiddelde aantal kilo\'s vanuit Transpas'

              }
            ]
          },
          {
            value: 'pp',
            label: 'PP',
            children: [
              {
                value: 'vE_OrderPP',
                label: 'Totaal',
                qlik: measures.persize.pp, 
                help: 'Het totaal aantal PP vanuit Transpas'

              },
              {
                value: 'vE_OrderPPAvg',
                label: 'Gemiddelde',
                qlik: measures.persize.avg_pp, 
                help: 'Het gemiddelde aantal PP vanuit Transpas'

              }
            ]
          },
          {
            value: 'zendinggrootte',
            label: 'Zendinggrootte',
            children: [
              {
                value: 'vE_OrderShipmentSize',
                label: 'Totaal',
                qlik: measures.persize.shipmentsize,
                help: 'Het totaal berekende LM. Waarbij M3 / KG / PP is omgerekend naar LM.'
              },
              {
                value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
                label: 'Omzet / LM',
                qlik: measures.persize.omzet_shipmentsize,
                help: 'De totale omzet / gedeeld door het totale aantal berekende LM.'
              },
              {
                value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
                label: 'Laden / LM',
                qlik: measures.persize.loading_shipmentsize,
                help: 'Het aantal laadminuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
              },
              {
                value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
                label: 'Lossen / LM',
                qlik: measures.persize.unloading_shipmentsize,
                help: 'Het aantal losminuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
              },
              {
                value: 'vE_KPI_OrderMinutenPerZendingGrootte',
                label: 'Tijd / LM',
                qlik: measures.persize.time_shipmentsize,
                help: 'Het aantal minuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
              },
            ]
          },
          

          //{
          //  value: 'vE_OrderShipmentSize',
          //  label: 'LM'
          //},
          
        ]
      },
    ]
  }
];
