import qlik from './qlik';
import {measures} from './qlik/qlik';
import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions } from '../orders/qlik/klant/klant';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Order Nummer',
            qlik: orderdimensions.order.ordernumberdisplay,
            new_value: "orderdimensions.order.ordernumberdisplay"
          }, 
          {
            label: 'Klant',
            value: 'klant',
            children: [
              {
                label: 'Naam',
                value: 'vD_Order.DebiteurNaam',
                qlik: orderdimensions.debtor.name,
                new_value: "orderdimensions.debtor.name"
              },
              {
                label: 'Nummer',
                value: 'vD_Order.DebiteurNummer',
                qlik: orderdimensions.debtor.number,
                new_value: "orderdimensions.debtor.number"
              },
              {
                  label: 'Groep',
                  value: 'vD_Order.DebiteurGroep',
                  qlik: orderdimensions.debtor.type,
                  new_value: "orderdimensions.debtor.type"
                }
            ]
          },
          {
            label: 'Laad Locatie',
            value: 'laadlocatie',
            children: [
              {
                value: 'vD_Order.LoadingLocation.ID',
                label: 'ID',
                qlik: orderdimensions.loadinglocation.id,
                new_value: "orderdimensions.loadinglocation.id"

              },
              {
                value: 'vD_Order.LoadingLocation.Name',
                label: 'Naam',
                qlik: orderdimensions.loadinglocation.name,
                new_value: "orderdimensions.loadinglocation.name"
              },
              {
                value: 'vD_Order.LoadingLocation.ZIPcode',
                label: 'Postcode',
                qlik: orderdimensions.loadinglocation.zipcode,
                new_value: "orderdimensions.loadinglocation.zipcode"
              },
              {
                value: 'vD_Order.LoadingLocation.City',
                label: 'Plaats',
                qlik: orderdimensions.loadinglocation.city,
                new_value: "orderdimensions.loadinglocation.city"
              },
              {
                value: 'vD_Order.LoadingLocation.Country',
                label: 'Land',
                qlik: orderdimensions.loadinglocation.country,
                new_value: "orderdimensions.loadinglocation.country"
              }
            ]
          },
          {
            label: 'Los Locatie',
            value: 'loslocatie',
            children: [
              {
                value: 'vD_Order.UnloadingLocation.ID',
                label: 'ID',
                qlik: orderdimensions.unloadinglocation.id,
                new_value: "orderdimensions.unloadinglocation.id"
              },
              {
                value: 'vD_Order.UnloadingLocation.Name',
                label: 'Naam',
                qlik: orderdimensions.unloadinglocation.name,
                new_value: "orderdimensions.unloadinglocation.name"
              },
              {
                value: 'vD_Order.UnloadingLocation.ZIPcode',
                label: 'Postcode',
                qlik: orderdimensions.unloadinglocation.zipcode,
                new_value: "orderdimensions.unloadinglocation.zipcode"
              },
              {
                value: 'vD_Order.UnloadingLocation.City',
                label: 'Plaats',
                qlik: orderdimensions.unloadinglocation.city,
                new_value: "orderdimensions.unloadinglocation.city"
              },
              {
                value: 'vD_Order.UnloadingLocation.Country',
                label: 'Land',
                qlik: orderdimensions.unloadinglocation.country,
                new_value: "orderdimensions.unloadinglocation.country"
              },
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            value: 'vD_Trip.TripNumber',
            label: 'Technisch Trip Nummer',
            qlik: ritdimensions.trip.number,
            new_value: "ritdimensions.trip.number"
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                label: 'Naam',
                value: 'vD_Driver.Name',
                qlik: ritdimensions.driver.name,
                new_value: "ritdimensions.driver.name"
              },
              {
                label: 'Nummer',
                value: 'vD_Driver.Number',
                qlik: ritdimensions.driver.number,
                new_value: "ritdimensions.driver.number"
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                label: 'Naam',
                value: 'vD_Vehicle.Number',
                qlik: ritdimensions.vehicle.number,
                new_value: "ritdimensions.vehicle.number"
              },
              {
                label: 'Nummer',
                value: 'vD_Vehicle.Name',
                qlik: ritdimensions.vehicle.name,
                new_value: "ritdimensions.vehicle.name"
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                label: 'Naam',
                value: 'vD_Trip.CreditorName',
                qlik: ritdimensions.charter.name,
                new_value: "ritdimensions.charter.name"
              },
              {
                label: 'Nummer',
                value: 'vD_Trip.CreditorNumber',
                qlik: ritdimensions.charter.number,
                new_value: "ritdimensions.charter.number"
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                label: 'Nummer',
                value: 'vD_Trip.Trailer.Number',
                qlik: ritdimensions.trailer.number,
                new_value: "ritdimensions.trailer.number"
              },
            ]
          },

        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar',
            qlik: ritdimensions.period.year,
            new_value: "ritdimensions.period.year"
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal',
            qlik: ritdimensions.period.quarter,
            new_value: "ritdimensions.period.quarter"
          },
          {
            value: 'vD_Month',
            label: 'Maand',
            qlik: ritdimensions.period.month,
            new_value: "ritdimensions.period.month"
          },
          {
            value: 'vD_Week',
            label: 'Week',
            qlik: ritdimensions.period.week,
            new_value: "ritdimensions.period.week"
          },
          {
            value: 'vD_Date',
            label: 'Datum',
            qlik: ritdimensions.period.date,
            new_value: "ritdimensions.period.date"
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_OrderTripOmzet',
            label: 'Totaal',
            qlik: measures.omzet.totaal,
            new_value: "measures.omzet.totaal"
          },
          {
            qlik: measures.omzet.freight,
            value: 'vE_OrderTripOmzetFreight',
            label: 'Vracht'
          },
          {
            qlik: measures.omzet.fuel,
            value: 'vE_OrderTripOmzetFuel',
            label: 'Brandstof'
          },
          {
            qlik: measures.omzet.other,
            value: 'vE_OrderTripOmzetOther',
            label: 'Overig'
          }
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_OrderTripKosten',
            label: 'Totaal',
            qlik: measures.kosten.totaal,
            new_value: "measures.kosten.totaal",
            help: 'Totaal van de directe kosten.'
          },
          {
            value: 'vE_OrderTripKostenCharter',
            label: 'Derden',
            qlik: measures.kosten.charter,
            new_value: "measures.kosten.charter",
            help: 'Kosten van charters.'
          },
          {
            value: 'vE_OrderTripKostenTol',
            label: 'Tol',
            qlik: measures.kosten.toll,
            new_value: "measures.kosten.toll",
            help: 'Tolkosten zoals deze uit Visual Planner komen.'
          },
          {
            value: 'vE_OrderTripKostenWarehouse',
            label: 'Warehouse',
            qlik: measures.kosten.warehouse,
            new_value: "measures.kosten.warehouse",
            help: 'Voorcalculatorische kosten berekend door de MargeMonitor voor de reservering van de directe crossdock kosten per zending'
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          // {
          //   value: 'vE_OrderTripAantalDossiers',
          //   label: 'Dossiers',
          // },
          {
            value: 'vE_OrderTripAantalOrders',
            label: 'Zendingen',
            qlik: measures.aantal.orders,
            new_value: "measures.aantal.orders",
            help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
          },
          {
            value: 'vE_OrderTripAantalRitten',
            label: 'Ritten',
            qlik: measures.aantal.ritten,
            new_value: "measures.aantal.ritten",
            help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_OrderTripGoedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur,
            new_value: "measures.kpi.goedkeur",
            help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kengetallen.'
          },

          {
            value: 'vE_KPI_OrderTripNormMarginPercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent,
            new_value: "measures.kpi.normmarginpercent",
            help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
          },
          {
            value: 'vE_KPI_OrderTripNormMarginOverOmzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet,
            new_value: "measures.kpi.normmarginomzet",
            help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_OrderTripOmzetPerUur',
            label: 'Omzet / Uur',
            new_value: "measures.own.omzetperuur",
            help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
          },
          {
            value: 'measures.own.omzetperuurexfuel',
            label: 'Omzet / Uur zonder DOT',
            qlik: measures.own.omzetperuurexfuel
          },
          {
            value: 'vE_KPI_OrderTripBezetting',
            label: 'Bezetting',
            qlik: measures.own.bezetting,
            new_value:'measures.own.bezetting'
          },
          {
            value: 'vE_OrderTripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            help: 'De geconfigureerde norm op trajectniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. ',
            new_value:'measures.own.normomzetperuur'
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed',
            qlik: measures.outsourced.percentage,
            new_value:'measures.outsourced.percentage',
            help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
          },
          {
            value: 'vE_KPI_OrderTripCharterMarge',
            label: 'Derden Marge',
            qlik: measures.outsourced.marge,
            help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. ',
            new_value:'measures.outsourced.marge'
          },
          {
            value: 'vE_OrderTripNormCharter',
            label: 'Norm',
            qlik: measures.outsourced.norm,
            help: 'De geconfigureerde norm op trajectniveau voor het uitbestede werk. De Derden Marge wordt getoetst aan deze norm. ',
            new_value:'measures.outsourced.norm',
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_OrderTripKM',
            label: 'Totaal',
            qlik: measures.km.km,
            new_value:'measures.km.km',
            help: 'Geeft de totale kilometers op trajectniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderTripKMFull',
            label: 'Beladen',
            qlik: measures.km.kmfull,
            help: 'Geeft de beladen kilometers op trajectniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
            new_value:'measures.km.kmfull',
          },
          {
            value: 'vE_OrderTripKMEmpty',
            label: 'Onbeladen',
            qlik: measures.km.kmempty,
            help: 'Geeft de onbeladen kilometers op trajectniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
            new_value:'measures.km.kmempty',
          },
          {
            value: 'vE_KPI_OrderTripOmzetPerKM',
            label: 'Omzet / KM',
            qlik: measures.km.omzetperkm,
            new_value:'measures.km.omzetperkm'
          },
          {
            value: 'vE_KPI_OrderTripOmzetPerKMFull',
            label: 'Omzet / beladen KM',
            qlik: measures.km.omzetperkmfull,
            new_value:'measures.km.omzetperkmfull'
          },
          {
            value: 'vE_KPI_OrderTripBelading',
            label: '% Beladen',
            qlik: measures.km.belading,
            new_value:'measures.km.belading'
          },
          {
            value: 'vE_OrderTripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            qlik: measures.km.normomzetperkm,
            help: 'De geconfigureerde norm op trajectniveau voor de Omzet / KM.',
            new_value:'measures.km.normomzetperkm'
          },
          {
            value: 'vE_OrderTripNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            qlik: measures.km.normomzetperkmfull,
            new_value:'measures.km.normomzetperkmfull',
            help: 'De geconfigureerde norm op trajectniveau voor de Omzet / beladen KM.' 
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_OrderTripUren',
            label: 'Totaal (Σ)',
            qlik: measures.uren.totaal,
            new_value:'measures.uren.totaal'
          },
          {
            value: 'vE_OrderTripUrenLoading',
            label: 'Laden (Σ)',
            qlik: measures.uren.loading,
            help: 'Het totaal aantal laaduren op trajectniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
            new_value:'measures.uren.loading' 
          },
          {
            value: 'vE_OrderTripUrenUnloading',
            label: 'Lossen (Σ)',
            qlik: measures.uren.unloading,
            new_value:'measures.uren.unloading',
            help: 'Het totaal aantal losuren op trajectniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderTripUrenDriving',
            label: 'Rijden (Σ)',
            qlik: measures.uren.driving,
            new_value:'measures.uren.driving',
            help: 'Het totaal aantal rijuren op trajectniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderTripUrenOther',
            label: 'Overig (Σ)',
            qlik: measures.uren.other,
            new_value:'measures.uren.other',
            help: 'Het totaal aantal overige uren op trajectniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderTripUrenAvg',
            label: 'Totaal (avg)',
            qlik: measures.uren.avg_totaal,
            new_value:'measures.uren.avg_totaal',
            help: 'Het gemiddeld aantal uren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderTripUrenAvgLoading',
            label: 'Laden (avg)',
            qlik: measures.uren.avg_loading,
            new_value:'measures.uren.avg_loading',
            help: 'Het gemiddeld aantal laaduren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderTripUrenAvgUnloading',
            label: 'Lossen (avg)',
            qlik: measures.uren.avg_unloading,
            help: 'Het gemiddeld aantal losuren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ',
            new_value:'measures.uren.avg_unloading',
          },
          {
            value: 'vE_OrderTripUrenAvgDriving',
            label: 'Rijden (avg)',
            qlik: measures.uren.avg_driving,
            new_value:'measures.uren.avg_driving',
            help: 'Het gemiddeld aantal rijuren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            value: 'vE_OrderTripUrenAvgOther',
            label: 'Overig (avg)',   
            qlik: measures.uren.avg_other,
            new_value:'measures.uren.avg_other',
            help: 'Het gemiddeld aantal overige uren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. ' 
          },
        ]
      },
    ]
  }
];
