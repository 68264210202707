const dimensions = {
  traject: {
    loadingfregiocode: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[OrderTrip.Loading.FinancialRegion]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='Laad F-Regio Code'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    loadingfregioomschrijving: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[OrderTrip.Loading.FinancialRegionDescription]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='Laad F-Regio Omschrijving'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    loadingoregiocode: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[OrderTrip.Loading.OperationalRegion]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='Laad F-Regio Code'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    loadingoregioomschrijving: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[OrderTrip.Loading.OperationalRegionDescription]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='Laad F-Regio Code'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    unloadingfregiocode: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[OrderTrip.Unloading.FinancialRegion]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='Los F-Regio Code'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    unloadingfregioomschrijving: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[OrderTrip.Unloading.FinancialRegionDescription]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='Los F-Regio Omschrijving'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    unloadingoregiocode: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[OrderTrip.Unloading.OperationalRegion]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='Los F-Regio Code'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },
    unloadingoregioomschrijving: {
      "qLibraryId": "",
      "qDef": {
        "qGrouping": "N",
        "qFieldDefs": [
          "[OrderTrip.Unloading.OperationalRegionDescription]"
        ],
        "qFieldLabels": [
          ""
        ],
        "qSortCriterias": [
          {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": 1,
            "qSortByAscii": 1,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          }
        ],
        "qNumberPresentations": [],
        "qReverseSort": false,
        "qActiveField": 0,
        "qLabelExpression": "='Los F-Regio Code'",
        "autoSort": true,
        "othersLabel": "Overige",
        "textAlign": {
          "auto": true,
          "align": "left"
        },
        "representation": {
          "type": "text",
          "urlLabel": ""
        }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
        "qOtherMode": "OTHER_OFF",
        "qOtherCounted": {
          "qv": "10"
        },
        "qOtherLimit": {
          "qv": "0"
        },
        "qOtherLimitMode": "OTHER_GE_LIMIT",
        "qSuppressOther": false,
        "qForceBadValueKeeping": true,
        "qApplyEvenWhenPossiblyWrongResult": true,
        "qGlobalOtherGrouping": false,
        "qOtherCollapseInnerDimensions": false,
        "qOtherSortMode": "OTHER_SORT_DESCENDING",
        "qTotalMode": "TOTAL_OFF",
        "qReferencedExpression": {
          "qv": ""
        }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
        "qv": "Overige"
      },
      "qTotalLabel": {
        "qv": ""
      },
      "qCalcCond": {
        "qv": ""
      },
      "qAttributeExpressions": [],
      "qAttributeDimensions": [],
      "qCalcCondition": {
        "qCond": {
          "qv": ""
        },
        "qMsg": {
          "qv": ""
        }
      }
    },

  },
  order:{
    dossier: {
      "qDef": {
          "qGrouping": "N",
          "qFieldDefs": [
              "[$(vD_^JKS.Order.Dossier)]"
          ],
          "qSortCriterias": [
              {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                      "qv": ""
                  },
                  "qSortByGreyness": 0
              }
          ],
          "qNumberPresentations": [],
          "qReverseSort": false,
          "qActiveField": 0,
          "qLabelExpression": "='$(vD_^JKS.Order.Dossier)'",
          "autoSort": true,
          "othersLabel": "Overige",
          "textAlign": {
              "auto": true,
              "align": "left"
          },
          "representation": {
              "type": "text",
              "urlLabel": ""
          }
      },
      "qNullSuppression": false,
      "qIncludeElemValue": false,
      "qOtherTotalSpec": {
          "qOtherMode": "OTHER_OFF",
          "qOtherCounted": {
              "qv": "10"
          },
          "qOtherLimit": {
              "qv": "0"
          },
          "qOtherLimitMode": "OTHER_GE_LIMIT",
          "qSuppressOther": false,
          "qForceBadValueKeeping": true,
          "qApplyEvenWhenPossiblyWrongResult": true,
          "qGlobalOtherGrouping": false,
          "qOtherCollapseInnerDimensions": false,
          "qOtherSortMode": "OTHER_SORT_DESCENDING",
          "qTotalMode": "TOTAL_OFF",
          "qReferencedExpression": {
              "qv": ""
          }
      },
      "qShowTotal": false,
      "qShowAll": false,
      "qOtherLabel": {
          "qv": "Overige"
      },
      "qTotalLabel": {
          "qv": ""
      },
      "qCalcCond": {
          "qv": ""
      },
    },
    }
};

export {dimensions};


