import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Doormiddel van onderstaande instellingen kan op orderniveau ingesteld worden welke waarden getoond worden.": "By using the settings below, you can set which values are shown at the order level.",
      "Doormiddel van onderstaande instellingen kan op ritniveau ingesteld worden welke waarden getoond worden.": "By using the settings below, you can set which values are shown at the trip level.",
      "Doormiddel van onderstaande instellingen kan op trajectniveau ingesteld worden welke waarden getoond worden.": "By using the settings below, you can set which values are shown at the traject level.",
      "Kies dimensies en meetwaarden of selecteer een voorgedefineerd rapport": "Choose dimensions and measures or select a predefined report",
      "Selecteer Dimensies en Meetwaarden": "Select Dimensions and Measures",
      "Rapport": "Report",
      "Presets binnen uw organisatie:": "Global presets",
      "Uw presets:": "User presets",
      "Preset aanmaken": "Create report",
      "Selecties meenemen": "Save datafilters",
      "Titel": "Title",
      "Omschrijving": "Description",
      "Vragen? <1>Neem contact met ons op!</1>": "Questions? <1>Contact us!</1>",
      "Omzet": "Turnover",
      "Orders": "Jobs",
      "Ritten": "Trips",
      "Berekening": "Calculation",
      "Totaal": "Total",
      "Eigen Werk": "Own Vehicles",
      "Goedgekeurd": "Approved",
      "Uitbesteed": "Outsourced",
      "Trajecten": "Trajects",
      "Charter Marge %": "Charter Margin %",
      "Omzet / Uur": "Turnover / Hour",
      "Charter Marge": "Charter Margin",
      "Top Omzet": "Most Turnover",
      "Top Marge": "Best Margin",
      "Flop Marge": "Less Margin",
      "Norm Marge": "Norm Margin",
      "Margeontwikkeling": "Development of Margin",
      "Omzetontwikkeling": "Turnover trend",
      "Slechte omzet": "Low performance", 
      "Uitleg": "Explanation",
      "Klant": "Customer",
      "Nieuwe Klanten": "New Customers",
      "Top Stijgers": "Top Gainers",
      "Klanten": "Customers",
      "Per Maand": "Per Month",
      "Trend": "Trend",
      "Verschil": "Delta",
      "% Aandeel": "Share (%)",
      '% Verschil': "Delta (%)",
      "Totalen": "Totals",
      "Overige": "Remaining",
      "(Directe) Kosten": "(Direct) Costs",
      "Kosten": "Costs",
      "Bezetting": "Occupancy",
      "Bezetting van de verschillende auto's": "Occupancy per vehicle",
      "Bezetting van het wagenpark door de tijd": "Occupancy from vehicles through time", 
      "Voor de chartermarge wordt enkel het kostendeel gebruikt dat hoort bij de uitbestede trajecten waarvan de orders zijn goedgekeurd": "For the charter margin, only the cost part belonging to the outsourced trajects for which the orders have been approved is used",
      "De netto-opbrengst (omzet - kosten) wordt afgezet tegen de omzet": "Net income (sales - costs) is compared with sales",
      "Voor de omzet/uur worden enkel de uren gebruikt die horen bij eigen trajecten waarvan de orders zijn goedgekeurd.": "For the turnover/hour, only the hours belonging to own trajects whose orders have been approved are used.",
      "Voor de omzet/uur word enkel de eigen omzet van orders die goedgekeurd zijn meegenomen": "For the turnover/hour, only the own turnover of orders that have been approved is taken into account.",
      "De netto-opbrengst (omzet - kosten) wordt afgezet tegen de gerealiseerde uren": "Net revenue (sales - costs) is compared to hours realized",
      "Voor de omzet/uur wordt enkel het kostendeel gebruikt dat hoort bij de eigen trajecten waarvan de orders zijn goedgekeurd": "For the turnover/hour, only the cost part that belongs to the own trajects of which the orders have been approved are used",
      "Voor de chartermarge word enkel de uitbestede omzet van orders die goedgekeurd zijn meegenomen": "For the charter margin, only the outsourced part of orders that have been approved are taken into account.",
      "Order soort": "Orderkind",
      "Uren": "Hours",
      "Slechte Omzet": "Bad Turnover",
      "Op Debiteur": "Based on Debtor",
      "Op Auto": "Based on Vehicle",
      "Op Chauffeur": "Based on Driver",
      "Afkeur": "Reject",
      "Aantal afgekeurde orders en ritten per afkeurreden": "Number of rejected orders and trips per reject reason",
      "Verloop van afkeur door de tijd": "Developmenth of rejected orders through time", 
      "Laadlocaties": "Loadinglocations",
      "Loslocaties": "Unloadinglocations",
      "Verdeling van klanten": "Customer share",
      "op basis van omzet": "based on turnover",
      "Verdeling van zendingtypes": "Number of orders per kind",
      "Verbinding met QlikSense controleren...": "Checking connection with QlikSense",
      "Kan geen verbinding maken met QlikSense. Is de QlikSense server bereikbaar vanaf uw huidige locatie?": "Cannot connect with QlikSense. Is the server reachable from your current location?",
      "uitsplitsing van omzet over de maanden": "development of turnover",
      "Uitsplitsing van omzet / uur  naar klant": "turnover/hour per customer",
      "uitsplitsing van omzet/uur over de maanden": "development of turnover / hour",
      "uitsplitsing van omzet/uur naar klant": "turnover/hour per customer",
      "uitsplitsing van omzet naar klant": "turnover per customer",
      "uitsplitsing van aantal zendingen over de maanden": "development of quantity",
      "uitsplitsing van aantal zendingen naar klant": "quantity per customer", 
      "Stap-voor-stap uitleg van de omzet/uur op orderniveau aan de hand van de huidige selecties": "Step-by-step explanation of Turnover / Hour at order level using current selections",
      "Stap-voor-stap uitleg van de chartermarge op orderniveau aan de hand van de huidige selecties": "Step-by-step explanation of Charter Margin at order level using current selections",
      "Op basis van eigen wagens en aanwezig op 50% lijst": "Own vehicle on low performance (50%) list",
      "Laatste jaar ten opzichte van voorgaande jaar": "Last year versus previous year",
      "Klanten met de meeste omzet": "Customers with most turnover",
      "Op basis van grootste 100 klanten en minimaal 66% goedkeur": "100 largest customers and > 66% OK",
      "In deze tabel worden de orders gerangschikt op basis van de omzet/uur die behaald wordt. Dit kan alleen voor orders die door eigen wagen wordt uitgevoerd. De laagst presterende 50% van de orders worden gemarkeerd als slecht. Door per klant te kijken hoeveel van de orders gemarkeerd worden als slecht kun je bepalen hoeveel van het order aanbod slecht presteert. De potentie geeft aan hoeveel er gewonnen kan worden als deze klant als de slecht presterende orders verbeterd worden tot de gestelde norm omzet/uur.": "In this table, the orders are ranked according to the turnover/hour achieved. This can only be done for orders executed by own car. The lowest performing 50% of the orders are marked as bad. By looking at how many of the orders per customer are marked as poor you can determine how much of the order supply is performing poorly. The potential indicates how much can be gained by this customer if the poorly performing orders are improved to the set standard turnover/hour.",
      "Laat de klanten zien met de hoogste marge zien. Dit is de marge ten opzichte van de gestelde normen. Het gaat hier om de 100 grootste klanten wat betref omzet met minimaal 66% goedgekeurde orders.": "Show customers with the highest overall margin. This is the margin based on the norms. Het gaat hier om de 100 grootste klanten wat betref omzet met minimaal 66% goedgekeurde orders.",
      "Geeft de ontwikkeling van ontwikkeling van de marge door de tijd weer. <br/><br/><strong>Norm Marge</strong> - De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen.<br/><strong>Omzet / Uur</strong> - Ontwikkeling van de omzet per uur door de tijd. Enkel voor de eigen auto's.<br/><strong>Charter Marge</strong> - Ontwikkeling van de marge op uitbesteed werk.  <br/><br/><13>Tip:</13> Maak onder de x-as een keuze voor Kwartaal / Maand / Week  voor het gewenste detailniveau.": "Shows the evolution of development of margin through time. <br/><br/><strong>Norm Margin</strong> - The Norm Margin combines the charter margin and revenue/hour to test them together against the set standards.<br/><strong>Turnover / Hour</strong> - Development of turnover per hour through time. For own cars only.<br/><strong>Charter Margin</strong> - Development of margin on outsourced work.  <br/><br/><13>Tip:</13> Below the x-axis, select Quarterly/Monthly/Weekly for the level of detail desired."
    }
  },
  nl: {
    translation: {
      "Welcome to React": "Bienvenue à React et react-i18next"
    }
  }
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "nl", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    debug: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
